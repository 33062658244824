import { Microphone, MicrophoneAltSlash } from "@styled-icons/fa-solid";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledChatAudioWrapper = styled.div`
  width: 4rem;
  height: 4rem;
`;

export const RecordingMic = styled.button<{ isRecognizing: boolean }>`
  position: relative;
  background-color: ${({ isRecognizing }) =>
        isRecognizing ? "#ff4d4d" : "transparent"};
  color: ${({ isRecognizing }) => (isRecognizing ? "#fff" : "#007bff")};
  border: 2px solid
    ${({ isRecognizing }) => (isRecognizing ? "#ff4d4d" : "#007bff")};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;

  ${({ isRecognizing }) =>
        isRecognizing &&
        `
      animation: ripple 0.75s infinite; /* Adjusted speed */
  `}

  &:hover {
    background-color: ${({ isRecognizing }) =>
        isRecognizing ? "#ff3333" : "#e6f7ff"};
    color: ${({ isRecognizing }) => (isRecognizing ? "#fff" : "#007bff")};
  }

  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 77, 77, 0.8);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(255, 77, 77, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 77, 77, 0);
    }
  }
`;



interface IChatAudio {
    onTranscriptChange: (text: string) => void;
    disabled: boolean;
    onStop: () => void;
}

const ChatAudio = forwardRef(({ disabled, onStop, onTranscriptChange }: IChatAudio, ref) => {
    useImperativeHandle(ref, () => ({
        stopAudio() {
            stopRecognition(true);
        },
        startAudio() {
            startRecognition();
        },
        isRecognizing() {
            return isRecognizing;
        }
    }));

    const handleRecognitionResult = useCallback((event: any) => {
        let fullTranscript = '';

        for (let i = 0; i < event.results.length; i++) {
            fullTranscript += event.results[i][0].transcript + ' ';
        }

        // Remove trailing space
        fullTranscript = fullTranscript.trim();

        onTranscriptChange(fullTranscript);
    }, [onTranscriptChange]);

    const createRecognitionInstance = () => {
        const SpeechRecognition =
            (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
        if (!SpeechRecognition) {
            console.error("Speech recognition is not supported in this browser.");
            toast.error("Speech recognition is not supported in this browser.");
            return null;
        }

        const recognitionInstance = new SpeechRecognition();        
        recognitionInstance.continuous = true;
        recognitionInstance.interimResults = true;
        recognitionInstance.onstart = () => setIsRecognizing(true);
        recognitionInstance.onend = () => setIsRecognizing(false);
        recognitionInstance.addEventListener('result', handleRecognitionResult);
        recognitionInstance.addEventListener('audiostart', () => setIsRecognizing(true));
        recognitionInstance.addEventListener('audioend', () => setIsRecognizing(false));
        return recognitionInstance;
    };

    const recognition = useMemo(() => createRecognitionInstance(), []);

    const timerRef = useRef(null);
    const [isRecognizing, setIsRecognizing] = useState(false);

    useEffect(() => {
        return () => {
            if (recognition){
                recognition.removeEventListener('result', handleRecognitionResult);
                recognition.removeEventListener('audiostart', () => setIsRecognizing(true));
                recognition.removeEventListener('audioend', () => setIsRecognizing(false));
                recognition.stop();
            }            
        };
    }, [recognition]);

    const startRecognition = () => {
        if (!recognition) return;
        recognition.start();
        if (timerRef.current) (timerRef.current as any).startSw();
    };

    const stopRecognition = (skipCallback?: boolean) => {
        if (!recognition) return;
        recognition.stop();
        if (!skipCallback) {
            onStop();
        }
        if (timerRef.current) (timerRef.current as any).resetSw(undefined, false);
        setIsRecognizing(false);
    };

    const toggleRecognition = () => {
        if (isRecognizing) {
            stopRecognition();
        } else {
            startRecognition();
        }
    };

    return (
        <StyledChatAudioWrapper className="position-absolute d-flex align-items-center justify-content-center flex-column">
            <div className="d-flex align-items-center justify-content-center">
                <RecordingMic
                    disabled={disabled}
                    className="start-button p-0 d-flex align-items-center justify-content-center"
                    title="Speak Answer"
                    onClick={toggleRecognition}
                    isRecognizing={isRecognizing} // Pass isRecognizing as a prop to StyledButton
                >
                    {isRecognizing ? <MicrophoneAltSlash height={'1rem'} /> : <Microphone height={'1rem'} />}
                </RecordingMic>
            </div>
            {/* <Timer ref={timerRef} /> */}
        </StyledChatAudioWrapper>
    );
});

export default ChatAudio;
