import { TextField } from '@material-ui/core';
import { ChatGptUserType } from 'containers/GPTChatBotContainer/useGPTChatBot';
import { StyledButton } from 'containers/QuestionCodingPane/Coding/AnswerCTA';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { submitResponseToAI } from 'services/evaluationPlatform';
import { RootState, useAppDispatch } from 'store';
import { setInterviewMeConversation, setShowInterviewMeBot } from 'store/splitView';
import styled from 'styled-components';
import { ISubmitResponseToAI, InterviewMeResponse } from 'types';
import { DEFAULT_TOKEN, TrackEnrollType } from 'utilities/constants';
import ChatAudio from './ChatAudio';
import { InterviewMeChatBox } from './InterviewMeChatBox';

import InterviewMeHeader from './InterviewMeHeader';
import { withSecurityFeatures } from 'components/HigherOrderComponents/withSecurityFeatures';
import { getEnrollmentType } from 'utilities';
import { useMessagePopup } from 'context/messagePopup';

const StyledChatContainer = styled.div`
    display: flex;
    background: white;
    transition: 1s;
    flex-direction: column;
    padding-left: 60px;
    padding-right: 25px;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 57px);
    position: absolute;
    width: 100%;
    z-index: 1000;
    bottom: 0;
    right: 0;
    .chat-window {
        display: flex;
        flex-direction: column;
        height: 90%;
        width: 100%;
        border-radius: 5px;
        background: white;
    }
    .chat-content {
    }
    .historyContainer {
        border-left: 2px solid RGB(200, 220, 255);
        border-right: 2px solid RGB(200, 220, 255);
        padding: 10px;
        overflow-y: scroll;
        flex-grow: 1;
    }
    .user-msg, .bot-msg {
        padding: 10px;
        border-radius: 5px;
        font-size: .9rem;
        position: relative;
        text-align: left;
        max-width: 80%;
    }
    .MuiOutlinedInput-adornedEnd {
        border: 1px solid RGB(200, 220, 255);
    }
    .bot-msg {
        background: linear-gradient(72deg, rgb(239, 245, 253) 0%, rgb(238, 238, 254) 79%);
        color: rgb(4, 3, 72);
    }
    .arrow-right, .arrow-left {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        position: absolute;
        top: 13px;
    }
    .arrow-left {
        left: -7px;
        border-right: 8px solid rgb(246, 248, 255);
    }
    .arrow-right {
        right: -7px;
        border-left: 8px solid #f1f1f1;
    }
    .user-msg {
        background: linear-gradient(108deg, rgb(246, 248, 255) 0%, rgb(220, 220, 220) 70%);
        color: rgb(60, 60, 60);
    }
    .bot-msg-container, .user-msg-container {
        display: flex;
        margin: 15px 0;
    }
    .bot-msg-container {
        justify-content: flex-start;
    }
    .user-msg-container {
        justify-content: flex-end;
    }
    .messaging-user-symbol, .messaging-bot-symbol {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .messaging-user-symbol {
        margin-left: 12.5px;
        background-color: #d8d8d8;
    }
    .messaging-bot-symbol {
        margin-right: 12.5px;
        background-color: #3d4e8d;
        color: #fff;
    }
    .pre-line-white-space {
        white-space: pre-wrap;
    }
    .submit-wrapper {
        border: 1px solid RGB(200,220,255);
        padding: 9px;
    }
    .text-field {
        padding: 1px;
        margin-left: 4rem;
    }
    .MuiOutlinedInput-multiline {
        padding: 2px 10px;
        height: 60px;
    }
`;
export interface IConversationScore {
    score: number;
    capability: string;
}

export interface IConversation {
    type: ChatGptUserType;
    text: string;
    score?: IConversationScore[];
    isError?: boolean;
    comments?: string;
    isHistory?: boolean;
}

interface IInterviewMeChatbox {
    onSave: (conversation: IConversation[]) => void;
}

const InterviewMeContainer = ({ onSave }: IInterviewMeChatbox) => {
    const isPlacement = getEnrollmentType() === TrackEnrollType.FORPLACEMENT;
    const dispatch = useAppDispatch();
    const endOfMessages = useRef<any>();
    const newAnswerFetched = useRef(false);
    const audioRef = useRef(null);
    const restartSpeechRecognition = useRef(false);
    const prevTranscript = useRef('');
    const textFieldRef = useRef(null);
    const [inputMessage, setInputMessage] = useState<string>('');
    const { candidateTrackId, currentAnswerId, questionTitle, currentAnswer } = useSelector(
        (state: RootState) => state.splitView.bot
    );
    const [loadingBotResponse, setLoadingBotResponse] = useState<boolean>(false);
    const { conversation } = useSelector((state: RootState) => state.splitView.bot);
    const [lastBotResponseState, setLastBotResponseState] = useState<InterviewMeResponse>();
    const message = useMessagePopup();

    const scrollToBottom = useCallback(() => {
        endOfMessages?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, [endOfMessages]);

    const handleSendMessage = useCallback((inputMessage: string) => {
        if (audioRef && audioRef.current && (audioRef.current as any).isRecognizing()) {
            (audioRef.current as any).stopAudio();
            restartSpeechRecognition.current = true;
        } else {
            restartSpeechRecognition.current = false;
        }
        prevTranscript.current = '';
        setInputMessage('');
        if (inputMessage.trim() === '') return;

        const newMessage = {
            type: ChatGptUserType.USER,
            text: inputMessage,
        };

        const updatedConversation = [...conversation, newMessage];

        let latestChatBotQuestion = null;
        for (let i = conversation.length - 1; i >= 0; i--) {
            const latestConversation = conversation[i];
            if (latestConversation.type === 0) {
                latestChatBotQuestion = latestConversation.text;
                break;
            }
        }
        const question = latestChatBotQuestion ?? questionTitle;
        dispatch(setInterviewMeConversation(updatedConversation));
        if (candidateTrackId && currentAnswerId && question && inputMessage) {
            interviewCandidate(candidateTrackId, currentAnswerId, question, inputMessage, updatedConversation);
        }
    }, [conversation, candidateTrackId, currentAnswerId, questionTitle, lastBotResponseState]);

    const handleTranscriptChange = (text: string) => {
        setInputMessage((prevTranscript.current ? (prevTranscript.current + "\n") : "") + text);
    }

    const handleInputChange = (event: any) => {
        setInputMessage(event.target.value);
        if (!currentAnswerId && !newAnswerFetched.current) {
            const saveinput: IConversation[] = [{ type: ChatGptUserType.USER, text: event.target.value }];
            newAnswerFetched.current = true;
            onSave(saveinput);
        }
    }

    const handleSubmitAnswer = useCallback(() => {
        message.confirm("This action will override your previous answer. Are you sure?", (response: any) => {
            onSave(conversation);
            dispatch(setShowInterviewMeBot(false));
        });
    }, [onSave, conversation, dispatch]);

    const handleRegenerateResponse = useCallback((message: IConversation) => {
        const updatedConversation = [...conversation];
        updatedConversation.pop();
        const lastUserMessage = updatedConversation[updatedConversation.length - 1];
        dispatch(setInterviewMeConversation(updatedConversation));

        let latestChatBotQuestion = null;
        const messageIdx = conversation.findIndex(botMsg => botMsg.isError === message.isError && botMsg.comments === message.comments);
        for (let i = messageIdx - 1; i >= 0; i--) {
            const latestConversation = conversation[i];
            if (latestConversation.type === 0) {
                latestChatBotQuestion = latestConversation.text;
                break;
            }
        }
        const question = latestChatBotQuestion ?? questionTitle;
        if (lastUserMessage && candidateTrackId && currentAnswerId && question) {
            interviewCandidate(candidateTrackId, currentAnswerId, question, lastUserMessage.text, updatedConversation);
        }
    }, [conversation, candidateTrackId, currentAnswerId, questionTitle, lastBotResponseState]);

    const interviewCandidate = useCallback(
        async (candidateTrackId: string, currentAnswerId: string, questionTitle: string, currentAnswer: string, conversation: IConversation[]) => {
            const payload: ISubmitResponseToAI = {
                token: DEFAULT_TOKEN,
                candidateTrackId: candidateTrackId,
                questionAnswerId: currentAnswerId,
                question: questionTitle,
                prompt: currentAnswer,
                answer: currentAnswer,
                temperature: 0.7,
                promptBuilderType: 'EVALUATION',
                parentEvaluationId: lastBotResponseState?.parentEvaluationId,
                followUpQuestionId: lastBotResponseState?.followUpQuestionId,
                levelOrder: 3,
                maxSortOrder: 0,
            };
            setLoadingBotResponse(true);
            let updatedConversation = [...conversation];
            try {
                const response = await submitResponseToAI(payload);
                const data = response.output as InterviewMeResponse;
                setLastBotResponseState(data);
                let comments = '';
                if (!data) {
                    throw ("No data received");
                }
                if (!data.followUpQuestion) {
                    comments = 'Thank you for the responses. Feel free to save your responses as the answer to this question.';
                }
                updatedConversation = [...conversation, { type: ChatGptUserType.BOT, text: data.followUpQuestion, score: isPlacement ? undefined : data.scores, comments }];
                dispatch(setInterviewMeConversation(updatedConversation));
            } catch (exception) {
                console.error(exception);
                let comments = `Error evaluating your response: \n`;
                updatedConversation = [...conversation, { type: ChatGptUserType.BOT, text: "", comments, isError: true }];
                dispatch(setInterviewMeConversation(updatedConversation));
            } finally {
                if (restartSpeechRecognition.current) {
                    (audioRef.current as any).startAudio();
                }
                setLoadingBotResponse(false);
            }
        },
        [lastBotResponseState, conversation]
    );

    const onClearConversation = () => {
        setLastBotResponseState(undefined);
    }

    useEffect(() => {
        scrollToBottom();
    }, [conversation, loadingBotResponse, scrollToBottom]);

    useEffect(() => {
        if (textFieldRef.current) {
            (textFieldRef.current as any).scrollTop = (textFieldRef.current as any).scrollHeight;
        }
    }, [inputMessage]);

    useEffect(() => {
        if (!conversation.length) {
            currentAnswer && handleSendMessage(currentAnswer)
        }
    }, []);

    return (
        <StyledChatContainer>
            <div className="chat-window">
                <InterviewMeHeader onClearConversation={onClearConversation} />
                <>
                    <div className="historyContainer">
                        <InterviewMeChatBox
                            conversation={conversation}
                            loadingBotResponse={loadingBotResponse}
                            handleRegenerateResponse={handleRegenerateResponse}
                        />
                        <div ref={endOfMessages}></div>
                    </div>
                    <div className='footer bg-white d-flex align-items-center justify-content-end submit-wrapper'>
                        <div className='d-flex align-items-center justify-content-between w-100'>
                            <div className='w-100 d-flex'>
                                <ChatAudio
                                    onStop={() => prevTranscript.current = inputMessage}
                                    disabled={loadingBotResponse}
                                    onTranscriptChange={handleTranscriptChange}
                                    ref={audioRef}
                                />
                                <TextField
                                    inputRef={textFieldRef}
                                    className='text-field w-100'
                                    disabled={loadingBotResponse}
                                    value={inputMessage}
                                    margin="none"
                                    id="outlined-textarea"
                                    maxRows={2}
                                    variant="outlined"
                                    placeholder="Enter text response here"
                                    onChange={handleInputChange}
                                    multiline
                                />
                            </div>
                            <div className='d-flex align-items-center flex-column justify-content-center'>
                                <StyledButton
                                    disabled={loadingBotResponse || !inputMessage || !currentAnswerId}
                                    size='small'
                                    variant='contained'
                                    color='primary'
                                    className='m-1 mb-0'
                                    width="9rem"
                                    onClick={() => handleSendMessage(inputMessage)}
                                    title={'Respond'}
                                >
                                    Respond
                                </StyledButton>
                                <StyledButton
                                    disabled={loadingBotResponse || conversation.findIndex(c => c.type === ChatGptUserType.USER) === -1}
                                    size='small'
                                    variant='contained'
                                    color='secondary'
                                    className='m-1'
                                    width="9rem"
                                    onClick={handleSubmitAnswer}
                                    title={'Save To Answer'}
                                >
                                    Save To Answer
                                </StyledButton>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </StyledChatContainer>
    );
};

export default withSecurityFeatures(InterviewMeContainer);
