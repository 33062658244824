import _ from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import { getValueBrowserStorage } from 'services/browserStorageService';
import { Expert_Login, QUESTION_STATUS_TEXT } from 'utilities/constants';
import SplitPaneWrapper from '../SplitPaneWrapper';
import { Answer, IQuestion } from '../types';
import AceEditorComponent from "./AceEditorComponent";
import "./Coding.css";
import TerminalComponent from './TerminalComponent';
import { RootState, useAppDispatch } from 'store';
import { setCode, setLanguage } from 'store/splitView';
import { useSelector } from 'react-redux';
import FolderStructure from './FolderStructure';
import { setCurrentCode } from '../../../store/splitView';
import { isPlacementTrack } from 'utilities/helperFunctions';

interface ICodingPane {
    currentQuestion: IQuestion;
    currentAnswer?: Answer;
    showConsole: boolean;
    codeOnChange?: (value: string, codeType: string) => void;
}

const CodingPane = ({ currentQuestion, currentAnswer, showConsole, codeOnChange }: ICodingPane) => {
    const dispatch = useAppDispatch();
    const editorRef = useRef();
    const terminalRef = useRef();
    const { language } = useSelector((state: RootState) => state.splitView.coding)

    const isExpertLogin = getValueBrowserStorage(Expert_Login) !== null;
    const debouncedCodeOnChange = useRef(
        _.debounce((value: string, codetype: string) => {
            codeOnChange && codeOnChange(value, codetype);
        }, 3000)
    ).current;


    const isDisabled =
        isExpertLogin || (isPlacementTrack() && window.location.pathname !== "/onboardCandidate") ||
        (currentAnswer &&
            ((currentQuestion && currentQuestion.question && currentQuestion.question.status !== QUESTION_STATUS_TEXT.UNANSWERED ||
                currentAnswer?.answer?.version > 0) &&
                ((currentQuestion && currentQuestion.question && currentQuestion.question.status !== QUESTION_STATUS_TEXT.UNANSWERED &&
                    currentQuestion.question.status !== QUESTION_STATUS_TEXT.ANSWERED) ||
                    currentAnswer?.answer?.version > 0))) || !codeOnChange;

    const handleInputChange = (value: string) => {
        debouncedCodeOnChange(value, language);
        dispatch(setCurrentCode(value));
    };

    useEffect(() => {
        dispatch(setCode(currentAnswer?.answer.codeAnswer || ""))
        dispatch(setLanguage(currentAnswer?.answer.codeType || 'javascript'))
    }, [currentAnswer]);

    useEffect(() => {
        (editorRef.current as any)?.onDrag();
        (terminalRef.current as any)?.onDrag();
    }, [showConsole])
    return (
        <>
            <SplitPaneWrapper hideFirstPane={true} sashWidth={'2px'} sashBackgroundColor='#000000b3' showToggle={false} initialPaneSize='20%' direction="vertical">
                <FolderStructure
                    onFileDelete={() => { }}
                    onFileRename={() => { }}
                    onFileSelect={() => { }}
                    onFolderDelete={() => { }}
                    onFolderRename={() => { }}
                />
                <div className={`d-flex flex-column h-100 w-100`}>
                    <SplitPaneWrapper sashBackgroundColor={"#000000b3"} maxPaneSize='' initialPaneSize='70%' sashHeight={"2px"} direction="horizontal" onDrag={() => { (editorRef.current as any)?.onDrag(); (terminalRef.current as any)?.onDrag(); }}>
                        <div id="code-editor-container" className={`${isDisabled ? "disable-editor" : ""}`}>
                            <AceEditorComponent
                                ref={editorRef}
                                onChange={handleInputChange}
                                initialCode={currentAnswer?.answer.codeAnswer}
                            />
                        </div>                        
                        {showConsole && <div id="terminal-container" className={`${isDisabled ? "disable-editor" : ""}`}>
                            <TerminalComponent ref={terminalRef} />
                        </div>} 
                    </SplitPaneWrapper>
                </div>
            </SplitPaneWrapper>
        </>
    );

};

export default CodingPane;