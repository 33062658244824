
import { Checkbox, CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ICity, IState, State } from 'country-state-city';
import { debounce } from 'lodash';
import { convertLocationToOptionDataType } from 'pages/ProfileReview/ProfileReview.util';
import React, { useCallback, useState } from 'react';
import { evaluationPlatformService } from 'services';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { MAP_BOX_LOCATION_TYPE, OptionsDataType } from 'types';
import { Candidate_Id } from 'utilities/constants';

type IProps = {
    selectedLocations: OptionsDataType[];
    handleChangeLocation: (key: string, value: OptionsDataType[]) => void;
    disabled: boolean;
    keyName: string;
    hideCheckboxInOption?: boolean;
}

const LocationSelector = (props: IProps) => {

    const [locationData, setLocationData] = useState<OptionsDataType[]>([]);
    const [loadingLocation, setLoadingLocation] = useState<boolean>(false);

    const debounceLocationData = useCallback(debounce(
        (filterString: string) => { updateLocationData(filterString) },
        400), []);

    const getOptionData = (locations: MAP_BOX_LOCATION_TYPE[]): OptionsDataType[] => {
        if (!locations || locations.length === 0) {
            return [];
        }
        const countries: { name: string, isoCode: string }[] = [];
        const states: IState[] = [];
        const cities: ICity[] = [];
        const addedIds = new Set<string>();
        for (const location of locations) {
            const address = location.context;
            // location is city
            if (address.district && address.region) {
                const [countryCode, stateCode] = address.region.regionCodeFull.toUpperCase().split("-");
                const key = countryCode + "-" + stateCode + "-" + location.name;
                if (!addedIds.has(key)) {
                    cities.push({
                        name: location.name,
                        countryCode,
                        stateCode
                    });
                    addedIds.add(key);
                }
            }
            // location is state
            else if (location.featureType === 'region' && address.country) {
                const countryCode = address.country.countryCode.toUpperCase();
                const stateCode = State.getStatesOfCountry(countryCode).find(state => state.name.toUpperCase() === location.name.toUpperCase())?.isoCode;
                const key = countryCode + "-" + stateCode;
                if (stateCode && !addedIds.has(key)) {
                    states.push({
                        name: location.name,
                        countryCode,
                        isoCode: stateCode
                    });
                    addedIds.add(key);
                }
            }
            // location is country
            else if (address.country) {
                const countryCode = address.country.countryCode.toUpperCase();
                if (!addedIds.has(countryCode)) {
                    countries.push({ name: location.name, isoCode: countryCode });
                    addedIds.add(countryCode);
                }
            }
        }
        return convertLocationToOptionDataType(countries, states, cities);
    }

    const updateLocationData = (filterString: string) => {
        if (filterString) {
            setLoadingLocation(true);
            const candidateId = getValueBrowserStorage(Candidate_Id);
            evaluationPlatformService.getLocationFinderMapBox(filterString, candidateId!)
                .then(res => {
                    const options = getOptionData(res.output.suggestions);
                    setLocationData(options);
                })
                .finally(() => setLoadingLocation(false))
        } else {
            setLocationData([]);
        }
    }

    return (
        <Autocomplete
            id="location-selector"
            disableCloseOnSelect
            value={props.selectedLocations}
            getOptionSelected={(option, value) => option.elementValue === value.elementValue && option.parent === value.parent}
            getOptionLabel={(option) => option.element}
            options={locationData}
            onChange={(e, value) => props.handleChangeLocation(props.keyName, value)}
            multiple
            loading={loadingLocation}
            filterOptions={(options) => options}
            noOptionsText={'Please search for a continent, country, state, or city'}
            renderOption={(option, { selected }) => {
                if (props.hideCheckboxInOption) {
                    return (<li>
                        {option.element}
                    </li>)
                }
                return (
                    <>
                        <Checkbox
                            style={{ marginRight: 8, color: '#315CD5' }}
                            checked={selected}
                        />
                        {option.element}
                    </>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={'Enter a continent, country, state, or city to search'}
                    variant="outlined"
                    onChange={(e) => {
                        debounceLocationData(e.target.value)
                    }}
                    onBlur={(e) => { debounceLocationData(e.target.value) }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loadingLocation ? <CircularProgress color="primary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    size='small'
                    name={props.keyName}
                />
            )}
        />
    )
}

export default LocationSelector;