import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

interface AudioStreamToText {
    onTranscriptChange?: (text: string) => void;
    onStop?: () => void;
}

const useAudioStreamToText = ({ onTranscriptChange, onStop }: AudioStreamToText) => {
    const [isRecognizing, setIsRecognizing] = useState(false);
    const recognitionRef = useRef<any>(null);
    //const fullTranscriptRef = useRef<string>("");
    const fullTranscriptArrRef = useRef<any>();

    const handleRecognitionResult = (event: any) => {
        let fullTranscript = '';

        for (let i = 0; i < event.results.length; i++) {
            fullTranscript += event.results[i][0].transcript + ' ';
        }

        // Remove trailing space
        fullTranscript = fullTranscript.trim();
        // fullTranscriptRef.current = fullTranscript;
        fullTranscriptArrRef.current = event.results;
        onTranscriptChange && onTranscriptChange(fullTranscript);
    }

    const createRecognitionInstance = () => {
        const SpeechRecognition =
            (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

        if (!SpeechRecognition) {
            console.error("Speech recognition is not supported in this browser.");
            toast.error("Speech recognition is not supported in this browser.");
            return null;
        }
        const recognitionInstance = new SpeechRecognition();
        recognitionInstance.continuous = true;
        recognitionInstance.interimResults = true;
        recognitionInstance.maxAlternatives = 2;
        recognitionInstance.lang = 'en-US';
        recognitionInstance.onstart = () => setIsRecognizing(true);
        recognitionInstance.onend = () => setIsRecognizing(false);
        recognitionInstance.addEventListener('result', handleRecognitionResult);
        recognitionInstance.addEventListener('audiostart', () => setIsRecognizing(true));
        recognitionInstance.addEventListener('audioend', () => setIsRecognizing(false));

        // Handling Errors 
        recognitionInstance.onerror = (event: any) => {
            console.error("Speech Recognition Error:", event.error);
            toast.error(`Speech Recognition Error: ${event.error}`);
    
            // Restart recognition on recoverable errors
            if (["network", "no-speech", "audio-capture"].includes(event.error)) {
                console.warn("Restarting recognition due to error:", event.error);
                setTimeout(() => recognitionInstance.start(), 1000);
            } else {
                setIsRecognizing(false);
            }
        };
        return recognitionInstance;
    };

    const recognition = useMemo(() => createRecognitionInstance(), []);

    useEffect(() => {
        const instance = createRecognitionInstance();
        if (instance) {
            recognitionRef.current = instance;
        }
        recognitionRef.current = recognition;

        return () => {
            if (recognition){
                recognition.removeEventListener('result', handleRecognitionResult);
                recognition.removeEventListener('audiostart', () => setIsRecognizing(true));
                recognition.removeEventListener('audioend', () => setIsRecognizing(false));
                recognition.stop();
            }
        };
    }, []);

    // useEffect(() => {
    //     recognitionRef.current = createRecognitionInstance();

    //     return () => {
    //         resetRecognition();
    //     };
    // }, []);

    const resetRecognition = () => {
        if (recognitionRef.current) {
            recognitionRef.current.removeEventListener('result', handleRecognitionResult);
            recognitionRef.current.stop();
        }
        recognitionRef.current = createRecognitionInstance();
    };

    const startRecognition = () => {
        if (!recognitionRef.current) {
            console.warn("Speech recognition is not supported in this browser.");
            return;
        }

        resetRecognition();
        //fullTranscriptRef.current = "";
        recognitionRef.current.start();
    };

    const stopRecognition = (skipCallback?: boolean) => {
        recognitionRef.current.stop();
        if (!skipCallback) {
            onStop && onStop();
        }
        setIsRecognizing(false);
        //fullTranscriptRef.current = "";
    };

    const toggleRecognition = () => {
        if (isRecognizing) {
            stopRecognition();
        } else {
            startRecognition();
        }
    };

    const getTranscribe = () => {
        //console.log(fullTranscriptArrRef.current);
        let fullTranscript = "";
        if (fullTranscriptArrRef.current){
            for (let i = 0; i < fullTranscriptArrRef.current.length; i++) {
                fullTranscript += fullTranscriptArrRef.current[i][0].transcript + ' ';
            }
        }
        //console.log(fullTranscript);
        return fullTranscript;
        
        //return fullTranscriptRef.current;
    }

    return {
        isRecognizing,
        startRecognition,
        stopRecognition,
        toggleRecognition,
        getTranscribe,
    };
};

export default useAudioStreamToText;
