import { TextField } from '@material-ui/core';
import { ChatGptUserType } from 'containers/GPTChatBotContainer/useGPTChatBot';
import { StyledButton } from 'containers/QuestionCodingPane/Coding/AnswerCTA';
import SplitPaneWrapper from 'containers/QuestionCodingPane/SplitPaneWrapper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store';
import { setInterviewMeConversation, setShowInterviewMeCodeBot } from 'store/splitView';
import styled from "styled-components";
import { InterviewMeResponse } from 'types';
import { IConversation } from '.';
import { submitResponseToAI } from '../../services/evaluationPlatform';
import { ISubmitResponseToAI } from '../../types';
import { DEFAULT_TOKEN, TrackEnrollType } from '../../utilities/constants';
import ChatAudio from './ChatAudio';
import { InterviewMeChatBox } from './InterviewMeChatBox';
import InterviewMeHeader from './InterviewMeHeader';
import { withSecurityFeatures } from 'components/HigherOrderComponents/withSecurityFeatures';
import { getEnrollmentType } from 'utilities';

interface IInterviewMeWrapper {
    children: React.ReactNode;
    onClose: () => void;
}

const StyledChatContainer = styled.div`
    display: flex;
    background: white;
    transition: 1s;
    flex-direction: column;
    padding-left: 60px;
    padding-right: 25px;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 37px);
    position: absolute;
    width: 100%;
    z-index: 1000;
    bottom: 0;
    right: 0;
    .chat-window {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 5px;
        border: 1px solid RGB(200, 220, 255);
        background: white;
    }
    .submit-wrapper {
        border: 1px solid RGB(200,220,255);
    }
    .text-field {
        padding: 1px;
        margin-left: 4rem;
    }
    .start-button {
        height: 1rem;
    }
    .MuiOutlinedInput-multiline {
        padding: 2px 10px;
        height: 60px;
    }
`;

const InterviewMeWrapper = ({ children, onClose }: IInterviewMeWrapper) => {
    const isPlacement = getEnrollmentType() === TrackEnrollType.FORPLACEMENT;
    const dispatch = useAppDispatch();
    const audioRef = useRef(null);
    const restartSpeechRecognition = useRef(false);
    const textFieldRef = useRef(null);
    const [inputMessage, setInputMessage] = useState<string>('');
    const prevTranscript = useRef('');
    const { candidateTrackId, currentAnswerId, questionTitle, currentAnswer, codeAnswer } = useSelector(
        (state: RootState) => state.splitView.bot
    );
    const [loadingBotResponse, setLoadingBotResponse] = useState<boolean>(false);
    const { conversation } = useSelector((state: RootState) => state.splitView.bot);
    const [lastBotResponseState, setLastBotResponseState] = useState<InterviewMeResponse>();


    const handleTranscriptChange = (text: string) => {
        setInputMessage((prevTranscript.current ? (prevTranscript.current + "\n") : "") + text);
    }

    const handleSendMessage = useCallback((codeAnswer?: string, inputMessage?: string) => {
        if (audioRef && audioRef.current && (audioRef.current as any).isRecognizing()) {
            (audioRef.current as any).stopAudio();
            restartSpeechRecognition.current = true;
        } else {
            restartSpeechRecognition.current = false;
        }
        prevTranscript.current = '';
        setInputMessage('');
        const trimmedInputMessage = (inputMessage || "").trim();
        const trimmedCodeAnswer = (codeAnswer || "").trim();

        if (!trimmedInputMessage && !trimmedCodeAnswer) {
            return;
        }

        let lastUserMessageIndex = -1;

        for (let i = conversation.length - 1; i >= 0; i--) {
            if (conversation[i].type === ChatGptUserType.USER && conversation[i].text) {
                lastUserMessageIndex = i;
                break;
            }
        }

        const shouldIncludeCodeAnswer =
            lastUserMessageIndex === -1 || conversation[lastUserMessageIndex].text !== trimmedCodeAnswer;

        const answer = shouldIncludeCodeAnswer
            ? trimmedCodeAnswer
                ? `${trimmedCodeAnswer}<br>${trimmedInputMessage}`
                : trimmedInputMessage || trimmedCodeAnswer
            : trimmedInputMessage;
        if (answer) {
            const newMessage = {
                type: ChatGptUserType.USER,
                text: shouldIncludeCodeAnswer ? trimmedCodeAnswer : "",
                comments: trimmedInputMessage,
            };

            const updatedConversation = [...conversation, newMessage];

            dispatch(setInterviewMeConversation(updatedConversation));

            let latestChatBotQuestion = null;
            for (let i = conversation.length - 1; i >= 0; i--) {
                const latestConversation = conversation[i];
                if (latestConversation.type === 0) {
                    latestChatBotQuestion = latestConversation.text;
                    break;
                }
            }
            const question = latestChatBotQuestion ?? questionTitle;
            if (candidateTrackId && currentAnswerId && question && answer) {
                interviewCandidate(candidateTrackId, currentAnswerId, question, answer, updatedConversation);
            }
        }
    }, [inputMessage, conversation, candidateTrackId, currentAnswerId, questionTitle, lastBotResponseState]);

    const handleRegenerateResponse = useCallback((message: IConversation) => {
        const updatedConversation = [...conversation];
        updatedConversation.pop();
        const lastUserMessage = updatedConversation[updatedConversation.length - 1];
        dispatch(setInterviewMeConversation(updatedConversation));

        let latestChatBotQuestion = null;
        const messageIdx = conversation.findIndex(botMsg => botMsg.isError === message.isError && botMsg.comments === message.comments);
        for (let i = messageIdx - 1; i >= 0; i--) {
            const latestConversation = conversation[i];
            if (latestConversation.type === 0) {
                latestChatBotQuestion = latestConversation.text;
                break;
            }
        }
        const question = latestChatBotQuestion ?? questionTitle;
        if (lastUserMessage && candidateTrackId && currentAnswerId && question) {
            interviewCandidate(candidateTrackId, currentAnswerId, question, lastUserMessage.text, updatedConversation);
        }
    }, [conversation, candidateTrackId, currentAnswerId, questionTitle, lastBotResponseState]);

    const interviewCandidate = useCallback(
        async (candidateTrackId: string, currentAnswerId: string, questionTitle: string, currentAnswer: string, conversation: IConversation[]) => {
            const payload: ISubmitResponseToAI = {
                token: DEFAULT_TOKEN,
                candidateTrackId: candidateTrackId,
                questionAnswerId: currentAnswerId,
                question: questionTitle,
                prompt: currentAnswer,
                answer: currentAnswer,
                temperature: 0.7,
                promptBuilderType: 'EVALUATION',
                parentEvaluationId: lastBotResponseState?.parentEvaluationId,
                followUpQuestionId: lastBotResponseState?.followUpQuestionId,
                levelOrder: 3,
                maxSortOrder: 0,
            };
            setLoadingBotResponse(true);
            let updatedConversation = [...conversation];
            try {
                const response = await submitResponseToAI(payload);
                const data = response.output as InterviewMeResponse;
                setLastBotResponseState(data);
                let comments = '';
                if (!data) {
                    throw ("No data received");
                }
                if (!data.followUpQuestion) {
                    comments = 'Thank you for the responses. Feel free to save your responses as the answer to this question.';
                }
                updatedConversation = [...conversation, { type: ChatGptUserType.BOT, text: data.followUpQuestion, score: isPlacement ? undefined : data.scores, comments }];
                dispatch(setInterviewMeConversation(updatedConversation));
            } catch (exception) {
                console.error(exception);
                let comments = `Error evaluating your response: \n`;
                updatedConversation = [...conversation, { type: ChatGptUserType.BOT, text: "", comments, isError: true }];
                dispatch(setInterviewMeConversation(updatedConversation));
            } finally {
                setInputMessage('');
                prevTranscript.current = '';
                if (restartSpeechRecognition.current) {
                    (audioRef.current as any).startAudio();
                }
                setLoadingBotResponse(false);
            }
        },
        [lastBotResponseState, conversation]
    );

    const handleClose = () => {
        dispatch(setShowInterviewMeCodeBot(false));
        onClose();
    }

    const onClearConversation = () => {
        setLastBotResponseState(undefined);
    }

    useEffect(() => {
        if (!conversation.length) {
            codeAnswer && handleSendMessage(codeAnswer, inputMessage)
        }
    }, [])

    useEffect(() => {
        if (textFieldRef.current) {
            (textFieldRef.current as any).scrollTop = (textFieldRef.current as any).scrollHeight;
        }
    }, [inputMessage, loadingBotResponse]);

    return <StyledChatContainer>
        <div className="chat-window">
            <InterviewMeHeader onClose={onClose} isCode={true} onClearConversation={onClearConversation} />
            <SplitPaneWrapper height='calc(100vh - 232px)' direction='vertical'>
                <InterviewMeChatBox
                    conversation={conversation}
                    loadingBotResponse={loadingBotResponse}
                    handleRegenerateResponse={handleRegenerateResponse}
                />
                {children}
            </SplitPaneWrapper>
            <div className='footer bg-white d-flex align-items-center justify-content-end submit-wrapper'>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <div className='w-100 d-flex'>
                        {!!currentAnswerId && <>
                            <ChatAudio
                                onStop={() => prevTranscript.current = inputMessage}
                                disabled={loadingBotResponse}
                                onTranscriptChange={handleTranscriptChange}
                                ref={audioRef}
                            />
                            <TextField
                                inputRef={textFieldRef}
                                className='text-field w-100'
                                disabled={loadingBotResponse}
                                value={inputMessage}
                                margin="none"
                                id="outlined-textarea"
                                maxRows={2}
                                variant="outlined"
                                placeholder="Enter text response here"
                                onChange={(e) => setInputMessage(e.target.value)}
                                multiline
                            />
                        </>}
                    </div>
                    <div className='d-flex align-items-center flex-column justify-content-center'>
                        <StyledButton
                            disabled={loadingBotResponse || !(codeAnswer || inputMessage) || !currentAnswerId}
                            size='small'
                            variant='contained'
                            color='primary'
                            className='m-1 mb-0'
                            width="9rem"
                            onClick={() => handleSendMessage(codeAnswer, inputMessage)}
                            title={'Respond'}
                        >
                            Respond
                        </StyledButton>
                        <StyledButton
                            disabled={loadingBotResponse || conversation.findIndex(c => c.type === ChatGptUserType.USER) === -1}
                            size='small'
                            variant='contained'
                            color='secondary'
                            className='m-1'
                            width="9rem"
                            onClick={handleClose}
                            title={'Save To Answer'}
                        >
                            Save To Answer
                        </StyledButton>
                    </div>
                </div>
            </div>
        </div>
    </StyledChatContainer>
}

export default withSecurityFeatures(InterviewMeWrapper);