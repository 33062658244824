import { useLoader } from "context/loaderDots";
import { useMessagePopup } from "context/messagePopup";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { evaluationPlatformService } from "services";
import { RootState } from "store";
import { setFeedback } from "store/evaluationPlatform";
import { API_TIMEOUT, DEFAULT_TOKEN } from "utilities/constants";
import { Answer, ICandidateTrackData, IQuestion } from "../types";

const useFeedback = () => {
    const loader = useLoader();
    const messagePopup = useMessagePopup();
    const dispatch = useDispatch();
    const [statusText, setStatusText] = useState<string>("");
    const candidateTrack: ICandidateTrackData = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack[0])
    const selectedCapabilityId = useSelector((state: RootState) => state.evaluationPlatform.selectedCapabilityId);


    const submitFeedbackToExpert = async (expertId: string, currentQuestion: IQuestion, currentAnswer: Answer, hideNotification?: boolean) => {
        loader.showLoader();
        const capabilityIds: string[] =
            (currentAnswer?.answer && currentAnswer.answer?.capabilityIds) ? [...currentAnswer.answer.capabilityIds] : [];
        if (capabilityIds.indexOf(selectedCapabilityId) < 0) capabilityIds.push(selectedCapabilityId);
        const payload = {
            token: DEFAULT_TOKEN,
            candidateTrackId: candidateTrack.candidateTrackId,
            questionId: currentQuestion?.question._id || "",
            questionAnswerId: currentAnswer?.answer._id || "",
            capabilityIds,
            expertId: expertId,
        };

        return evaluationPlatformService.submitResponseToExpert(payload)
            .then((res: any) => {
                if (res.apiStatus === "SUCCESS" && currentAnswer) {
                    const feedback = {
                        ...res.output,
                        expertId,
                        capabilities: currentQuestion?.question.capabilities,
                    };
                    dispatch(setFeedback(feedback));
                    !hideNotification && messagePopup.success('Your response is submitted');
                    setStatusText('Submitted for Review');
                    return feedback;
                } else {
                    !hideNotification && messagePopup.fail('Submission failed. Please try again.');
                    return null;
                }
            })
            .catch((err: any) => {
                if (err.message === API_TIMEOUT.message) {
                    !hideNotification && messagePopup.fail('Request timeout! Submission aborted.');
                } else {
                    !hideNotification && messagePopup.fail('Submission failed. Please try again.');
                }
                return null
            }).finally(() => {
                loader.hideLoader();
            });
    };

    return { statusText, submitFeedbackToExpert }
}

export default useFeedback;