import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store"
import { addMessageToGPTBot, updateChatGptBotState } from "store/evaluationPlatform"

export enum ChatGptUserType {
    BOT,
    USER
}

export enum CustomElementsName {
    ConfirmButton,
    FeedbackSystem,
}

export const useGPTBot = () => {

    const dispatch = useDispatch();
    const chatGPTBotState = useSelector((state: RootState) => state.evaluationPlatform.chatGPTBotState);

    const setLoadingResponse = (loadingBotResponse: boolean) => {
        dispatch(updateChatGptBotState({
            loadingBotResponse
        }))
    }

    const showBot = (botName: string, loadingBotResponse: boolean, prompts?: string[], initialChatQuestion?: string) => {
        dispatch(updateChatGptBotState({
            botName,
            selected: true,
            loadingBotResponse,
            prompts: prompts?.length === 0 ? [] : prompts,
            messages: [{ from: ChatGptUserType.BOT, content: initialChatQuestion }]
        }))
    }

    const hideBot = () => {
        dispatch(updateChatGptBotState({
            botName: '',
            selected: false,
            loadingBotResponse: false,
            messages: []
        }))
    }

    const addTextMsgToBot = (from: ChatGptUserType, msg: string) => {
        dispatch(addMessageToGPTBot({ from, content: replaceLineBreaks(msg) }))
    }

    const replaceLineBreaks = (str: String) => {
        const n = str.length;
        for (let i = 0; i < n; i++) {
            let currVal = str.charAt(i);
            if (currVal !== '\n') {
                return str.substring(i, n)
            }
        }
        return '';
    }

    const addCustomElementToChat = (from: ChatGptUserType, customElementName: CustomElementsName, props?: any) => {
        props = props ? props : {};
        dispatch(addMessageToGPTBot({ from, content: '', containsCustomElement: true, customElementName, props }))
    }

    return {
        showBot,
        hideBot,
        addTextMsgToBot,
        chatGPTBotState,
        setLoadingResponse,
        addCustomElementToChat
    }
}