import { css } from '@emotion/core';
import { OnboardingModal } from 'components/OnboardingModal';
import { TreeSidebar } from 'containers';
import GPTChatBotContainer from 'containers/GPTChatBotContainer';
import { useGPTBot } from 'containers/GPTChatBotContainer/useGPTChatBot';
import ChatBot from 'containers/InterviewHelpLandbot';
import { SuccessPayment } from 'containers/PlanAndPayment/PaymentAcknowledgement/successPayment';
import QuestionCodingPane from 'containers/QuestionCodingPane';
import SplitPaneWrapper from 'containers/QuestionCodingPane/SplitPaneWrapper';
import { useMessagePopup } from 'context/messagePopup';
import _, { get } from 'lodash';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import BeatLoader from 'react-spinners/BeatLoader';
import { getValueBrowserStorage, setValueBrowserStorage } from 'services/browserStorageService';
import { RootState, useAppDispatch } from 'store';
import {
  candidateViewedExpertFeedback, chatWithExpert, createCandidateTrackForCandidate, getAnswerStatus, getDetailsForCandidateByCandidateAndTrackId, getDetailsForCandidatebyCandidateTrackId, handleEdit, saveCandidateLastActivity, saveResponseForQuestionOfCandidateTrack, setAllFeedbacks, setAnswer, setCapability, setCurrentAnsVersionId, setFeedback,
  setQuestionFeedbackViewed, setQuestionId, setScrollPosition, setTriggerFeedback as setTriggerFeedbackStore, submitResponseToExpert
} from 'store/evaluationPlatform';
import { setTrackInfoForPayment } from 'store/payment';
import styled from 'styled-components';
import { notEmpty, post } from 'utilities';
import { Candidate_Id, Candidate_Track_Id, ConfigTypes, Content_Height, DEFAULT_TOKEN, Expert_Login, Expert_Session_Id } from 'utilities/constants';
const API_PREFIX = 'evaluationPlatform';

Modal.setAppElement(`#root`);

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  display: block;
`;

const StyledQuestionPage = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  .content {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    z-index: 100;
    width: 100%;
  }
  .main {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    min-height: 100%;
    background-color: #eeeeee;
  }
`;

const QuestionPage = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMaximizeContent } = useSelector((state: RootState) => state.evaluationPlatform);
  const capability = get(props.currentTrack, 'candidateTrack[0].capabilities');
  const trackName = get(props.currentTrack, 'candidateTrack[0].title');
  const { candidateTrackId } = props;
  const category = get(props.currentTrack, 'candidateTrack[0].category');
  const [triggerFeedback, setTriggerFeedback] = useState<ITriggerFeedback>();
  const [boatInfo, setBoatInfo] = useState<string>("");
  const dispatch = useDispatch();
  const storeDispatch = useAppDispatch()
  const { completePaymentAcknowledgement } = SuccessPayment();
  const message = useMessagePopup();
  const { search } = useLocation();
  const _queryParams: any = queryString.parse(search);
  const currentCandidateId = getValueBrowserStorage(Candidate_Id);
  const expertLogin = (getValueBrowserStorage(Expert_Login) == null) ? false : true;
  const [showOnboardingModal, setShowOnboardingModal] = useState<boolean>(false);
  const candidateMarketValue: any = useSelector((state) => state);
  const MarketId = candidateMarketValue?.evaluationPlatform?.candidate?.marketId;
  const { chatGPTBotState } = useGPTBot();
  
  useEffect(() => {
    if (props.botSelected === true && props.selectedCapabilityId) {
      const capabilityIdx = _.findIndex(capability, ['capabilityId', props.selectedCapabilityId]);
      setBoatInfo(capability[capabilityIdx].boatInfo);
    }
    if (props.botSelected === false) {
      setBoatInfo("");
    }
  }, [props.selectedCapabilityId, props.botSelected])

  useEffect(() => {
    completeOngoingPayment();
    if (expertLogin) {
      setShowOnboardingModal(false);
    }

  }, []);

  useEffect(() => {
    if (currentCandidateId) {
      getCandidateOnboardingConfig();
    }
  }, []);

  
  useEffect(() => {
    const savedCandidateTrackId = getValueBrowserStorage(Candidate_Track_Id);
    const savedCandidateId = getValueBrowserStorage(Candidate_Id);
    if (props.currentTrack === null || props?.currentTrack?.candidate?._id !== savedCandidateId) {
      if (savedCandidateTrackId !== null && savedCandidateTrackId !== 'undefined') {
        if (_queryParams.candidateTrackId) {
          props.getDetailsForCandidatebyCandidateTrackId({
            token: DEFAULT_TOKEN,
            candidateTrackId: _queryParams.candidateTrackId,
          });
        } else if (_queryParams.lptrackid && _queryParams.lpemail) {
          dispatch(getDetailsForCandidateByCandidateAndTrackId({
            token: DEFAULT_TOKEN,
            candidateId: savedCandidateId!,
            trackId: _queryParams.lptrackid
          }))
          enqueueSnackbar("We have issue you a default password and sent you a reset password email in your inbox", {
            variant: 'error',
            autoHideDuration: 2500,
          });
        }
        else if (_queryParams.lptrackid && !_queryParams.lpemail) {
          (storeDispatch(createCandidateTrackForCandidate({
            token: DEFAULT_TOKEN,
            trackId: _queryParams.lptrackid,
            candidateId: savedCandidateId!,
          })) as any).then((response: any) => {
            if (response?.payload?.output) {
              setValueBrowserStorage(
                'candidateTrackId',
                response.payload.output
                  .candidate.lastCandidateSavedSetting.lastCandidateTrackIDWorkedOn
              );
            }
            if (response?.error?.message === "This track is already exists with this username") {
              dispatch(getDetailsForCandidateByCandidateAndTrackId({
                token: DEFAULT_TOKEN,
                candidateId: savedCandidateId!,
                trackId: _queryParams.lptrackid
              }))
            }
            props.history.push('question')
          })
        } else {
          props.getDetailsForCandidatebyCandidateTrackId({
            token: DEFAULT_TOKEN,
            candidateTrackId: savedCandidateTrackId,
          });
        }
      }
    } else {
      if (props.paymentTrack?.trackId !== get(props.currentTrack, 'candidateTrack[0].trackId')) {
        dispatch(setTrackInfoForPayment({
          trackId: get(props.currentTrack, 'candidateTrack[0].trackId'),
          trackPlan: get(props.currentTrack, 'candidateTrack[0].displayPlanName'),
          trackName: get(props.currentTrack, 'candidateTrack[0].title'),
          planState: get(props.currentTrack, 'candidateTrack[0].planState')
        }));
      }
    }

  }, [capability]);

  const completeOngoingPayment = () => {
    completePaymentAcknowledgement(Expert_Session_Id).then((expertData: any) => {
      if (notEmpty(expertData)) {
        message.load('Payment Successful... Submitting your response');
        dispatch(setTriggerFeedbackStore({
          expertId: expertData.responseJson.metadata.expertId,
          questionId: expertData.responseJson.metadata.questionId,
          answerId: expertData.responseJson.metadata.answerId
        }));
        setTriggerFeedback({
          expertId: expertData.responseJson.metadata.expertId,
          questionId: expertData.responseJson.metadata.questionId,
          answerId: expertData.responseJson.metadata.answerId
        });
      }
    });
  }

  const goToPaymentPage = () => {
    props.history.push({
      pathname: '/settings/payments',
    });
  }

  const markAsDone = async () => {
    await post(API_PREFIX + '/setCandidateConfig', { token: DEFAULT_TOKEN, candidateId: currentCandidateId, configs: [{ key: ConfigTypes.CANDIDATE_ON_BOARDING_VIDEO_INFO, value: true }] })
    setShowOnboardingModal(false);
  }

  const getCandidateOnboardingConfig = async () => {
    const result = await post(API_PREFIX + '/getCandidateConfig', { token: DEFAULT_TOKEN, candidateId: currentCandidateId, type: ConfigTypes.CANDIDATE_ON_BOARDING_VIDEO_INFO });
    setShowOnboardingModal(result.output.configs ? !result.output.configs.value : true);
  }

  const goToOnboardingVideo = () => {
    props.history.push('settings/onboarding')
  }

  return (
    <StyledQuestionPage>
      <div className='w-100'>
        <SplitPaneWrapper hideFirstPane={isMaximizeContent} height={Content_Height} direction='vertical' draggable={false} initialPaneSize='305px'>
          <TreeSidebar
            setScrollPosition={props.setScrollPosition}
            isMaximizeContent={isMaximizeContent}
            trackName={trackName}
            category={category}
            setCapability={props.setCapability}
            setQuestionId={props.setQuestionId}
            setCurrentAnsVersionId={props.setCurrentAnsVersionId}
            videos={props.videos}
          />
          <div>
            {(props.botSelected === true) && <ChatBot url={boatInfo} setBotInfo={setBoatInfo} key={boatInfo} />}
            {(chatGPTBotState.selected === true) && <GPTChatBotContainer chatWithGPT={props.chatWithExpert} questionId={props.currentQuestionId} currentCandidateId={currentCandidateId!} />}
            {((props.botSelected || chatGPTBotState.selected) === true) ? null : props.loading ? (
              <BeatLoader css={override} color={'#3694D7'} loading={props.loading} />
            ) : (
              <QuestionCodingPane />
              // :
              // <QuestionContainer
              //   candidateTrackId={candidateTrackId}
              //   capability={capability}
              //   setCapability={props.setCapability}
              //   selectedCapabilityId={props.selectedCapabilityId}
              //   setAnswer={props.setAnswer}
              //   saveResponseForQuestionOfCandidateTrack={props.saveResponseForQuestionOfCandidateTrack}
              //   Modal={Modal}
              //   setQuestionId={props.setQuestionId}
              //   currentQuestionId={props.currentQuestionId}
              //   submitResponseToExpert={props.submitResponseToExpert}
              //   chatWithExpert={props.chatWithExpert}
              //   setFeedback={props.setFeedback}
              //   handleEdit={props.handleEdit}
              //   setQuestionFeedbackViewed={props.setQuestionFeedbackViewed}
              //   candidateViewedExpertFeedback={props.candidateViewedExpertFeedback}
              //   candidate={props.candidate}
              //   setCurrentAnsVersionId={props.setCurrentAnsVersionId}
              //   saveCandidateLastActivity={props.saveCandidateLastActivity}
              //   goToPaymentPage={goToPaymentPage}
              //   triggerFeedback={triggerFeedback}
              //   setTriggerFeedback={setTriggerFeedback}
              // />
            )}
            {MarketId !== '635d070b5206f3f90d24d524' && (!expertLogin) && showOnboardingModal && <OnboardingModal onMarkDone={markAsDone} goToVideo={goToOnboardingVideo}></OnboardingModal>}
          </div>
        </SplitPaneWrapper>
      </div>
    </StyledQuestionPage>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.evaluationPlatform.loading,
  triggerFeedback: state.evaluationPlatform.triggerFeedback,
  currentTrack: state.evaluationPlatform.currentTrack,
  candidateTrackId: state.evaluationPlatform.currentTrack?.candidateTrack?.[0].candidateTrackId,
  selectedCapabilityId: state.evaluationPlatform.selectedCapabilityId,
  botSelected: state.evaluationPlatform.landBotSelected,
  currentQuestionId: state.evaluationPlatform.currentQuestionId,
  candidate: state.evaluationPlatform.candidate,
  paymentTrack: state.payment,
  videos: state.evaluationPlatform.currentTrack?.candidateTrack?.[0].videos,
});

const mapDispatchToProps = {
  setAnswer,
  setCapability,
  setQuestionId,
  saveResponseForQuestionOfCandidateTrack,
  submitResponseToExpert,
  setFeedback,
  handleEdit,
  setAllFeedbacks,
  setQuestionFeedbackViewed,
  candidateViewedExpertFeedback,
  getDetailsForCandidatebyCandidateTrackId,
  getAnswerStatus,
  setCurrentAnsVersionId,
  saveCandidateLastActivity,
  setScrollPosition,
  chatWithExpert,
};

interface ITriggerFeedback {
  questionId: string;
  expertId: string;
  answerId: string;
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage);
