import { IconContainer } from 'components/Common/IconContainer';
import { ShareQuestion } from 'components/Question/QuestionField/AnswerSet/AnswerField/ShareQuestion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ChevronLeft, ChevronRight } from 'styled-icons/material-outlined';
import { answerType } from 'utilities/constants';
import { ToogleTypes } from '../Coding/AnswerCTA';
import CodingPane from '../Coding/CodingPane';
import CustomAnswerTabs from '../Coding/CustomAnswerTabs';
import RecordPane from '../Coding/RecordPane';
import SketchingPane, { ISketchUpdate } from '../Coding/SketchingPane';
import TextAreaPane from '../Coding/TextAreaPane';
import UploadPane from '../Coding/UploadPane';
import FeedbackComponent from '../Feedback/FeedbackComponent';
import SplitPaneWrapper from '../SplitPaneWrapper';
import { Answer, IQuestion } from '../types';
import ReactQuill from 'react-quill';
import PortalContainer from 'components/PortalContainer';

interface AnswerTabContainerProps {
    tabValue: number;
    handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
    answerVersion: number;
    currentQuestion?: IQuestion;
    currentAnswer?: Answer;
    handlePreviousVersion: () => void;
    handleNextVersion: () => void;
    sketchOnChange?: (data: ISketchUpdate) => void;
    questionStatusText?: string;
    RTOnChange?: (value: string, oldValue: string, source: string) => void;
    codeOnChange?: (newCode: string, codeType: string) => void;
    toggleType: ToogleTypes;
    onRecordSubmit?: (isCancel?: boolean, containRecording?: boolean) => void;
    isReadOnly?: boolean;
    candidateId?: string;
    onShare?: () => void;
    isMeetingView?: boolean;
    rteRef?: React.MutableRefObject<ReactQuill | null>;
    portalReferenceId?: string;
}

const AnswerTabContainer = ({
    tabValue,
    isMeetingView,
    rteRef,
    handleTabChange,
    answerVersion,
    currentQuestion,
    currentAnswer,
    handlePreviousVersion,
    handleNextVersion,
    sketchOnChange,
    questionStatusText,
    RTOnChange,
    codeOnChange,
    toggleType,
    onRecordSubmit,
    isReadOnly,
    candidateId,
    onShare,
    portalReferenceId,
}: AnswerTabContainerProps) => {
    const { addSketchInProgress } = useSelector((state: RootStateOrAny) => state.splitView);
    const sketchingPaneRef = useRef(null);
    const feedbackSketchingRef = useRef(null);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [tooggles, setToggles] = useState<ToogleTypes>(toggleType)

    const canShareResponceWithCommunity = (): boolean => {
        const feedbackStatus = currentAnswer?.feedbacks?.[0]?.feedbackStatus;
        return feedbackStatus === 'EXPERT_GIVES_FEEDBACK' || feedbackStatus === 'FEEDBACK_VIEWED_BY_CANDIDATE';
    }

    useEffect(() => {
        setToggles(toggleType);
    }, [toggleType])

    const handleDrag = () => {
        if (sketchingPaneRef && sketchingPaneRef.current) {
            (sketchingPaneRef.current as any).onDrag();
        }
        if (feedbackSketchingRef && feedbackSketchingRef.current) {
            (feedbackSketchingRef.current as any).onDrag();
        }
    }

    const memoizedTextAreaPane = useMemo(() => (
        tabValue === 0 && currentQuestion && currentQuestion?.question.answerType !== answerType.CODE && (
            <TextAreaPane
                key={currentAnswer?.answer?._id || "newTextArea"}
                currentQuestion={currentQuestion}
                currentAnswer={currentAnswer}
                RTOnChange={RTOnChange}
                answerVersion={answerVersion}
                ref={rteRef}
            />
        )
    ), [tabValue, currentQuestion, currentAnswer, answerVersion, RTOnChange]);

    const memoizedCodingPane = useMemo(() => (
        tabValue === 1 && currentQuestion && (
            <>
                {!portalReferenceId ? <CodingPane
                    key={currentAnswer?.answer?._id || "newCoding"}
                    currentQuestion={currentQuestion}
                    currentAnswer={currentAnswer}
                    codeOnChange={codeOnChange}
                    showConsole={toggleType === "Console"}
                /> : <PortalContainer portalRootId={portalReferenceId} >
                    <CodingPane
                        key={currentAnswer?.answer?._id || "newCoding"}
                        currentQuestion={currentQuestion}
                        currentAnswer={currentAnswer}
                        codeOnChange={codeOnChange}
                        showConsole={toggleType === "Console"}
                    />
                </PortalContainer>}
            </>
        )
    ), [tabValue, currentQuestion, currentAnswer, codeOnChange, toggleType, portalReferenceId]);

    const memoizedRecordPane = useMemo(() => (
        currentQuestion && (
            <RecordPane
                currentQuestion={currentQuestion}
                currentAnswer={currentAnswer}
                answerVersion={answerVersion}
                onsubmit={onRecordSubmit}
                isReadOnly={isReadOnly}
                candidateId={candidateId}
                setIsRecording={setIsRecording as any}
                className={tabValue === 3 ? 'record-pane visible' : 'record-pane'}
            />
        )
    ), [tabValue, currentQuestion, currentAnswer, answerVersion, onRecordSubmit]);

    const memoizedUploadPane = useMemo(() => (
        tabValue === 4 && currentQuestion && (
            <UploadPane
                currentQuestion={currentQuestion}
                currentAnswer={currentAnswer}
                answerVersion={answerVersion}
                candidateId={candidateId}
                isReadOnly={isReadOnly}
            />
        )
    ), [tabValue, currentQuestion, currentAnswer, answerVersion]);

    const memoizedSketchingPane = useMemo(() => {
        return (
            tabValue === 2 && currentQuestion && currentQuestion?.question.answerType !== answerType.CODE && <SketchingPane
                ref={sketchingPaneRef}
                currentQuestion={currentQuestion}
                currentAnswer={currentQuestion?.answers[answerVersion]}
                onSketchUpdate={sketchOnChange}
                isReadOnly={isReadOnly}
                answerVersion={answerVersion}
            />
        )
    }, [tabValue, currentQuestion, answerVersion])

    return (
        <SplitPaneWrapper
            sashHeight='2px'
            direction="horizontal"
            initialPaneSize={isReadOnly ? 'inherit' : tooggles === "Feedback" ? "70%" : "100%"}
            onDrag={handleDrag}
        >
            <SplitPaneWrapper sashHeight='0px' direction="horizontal" draggable={false} initialPaneSize={"40px"}>
                <CustomAnswerTabs
                    tabValue={tabValue}
                    handleTabChange={handleTabChange}
                    isCodeAvailable={currentQuestion?.question.answerType === answerType.CODE}
                    isDrawAvailable={currentQuestion?.question.answerType !== answerType.CODE}
                    isRTEAvailable={currentQuestion?.question.answerType !== answerType.CODE}
                    isDrawDisabled={!currentAnswer?.answer.sketchAvailable && addSketchInProgress}
                    isRecordAvailable={currentQuestion?.question.answerType !== answerType.CODE}
                    isRecording={isRecording}
                >
                    <div className='d-flex justify-content-between'>
                        {!isReadOnly && currentQuestion?.answers && !isMeetingView && canShareResponceWithCommunity() && <ShareQuestion splitView={true} answers={currentQuestion.answers} questionId={currentQuestion.question._id} onShare={onShare} />}
                        <div className="d-flex align-items-center justify-content-end p-2">
                            <IconContainer
                                height={"24px"}
                                color={answerVersion === 0 && "#babfc7"}
                                icon={ChevronLeft}
                                onClick={handlePreviousVersion}
                                disabled={answerVersion === 0}
                            />
                            {!isReadOnly && <span className='small'>{answerVersion === 0 ? "Current Answer" : `v ${answerVersion}`}</span>}
                            {isReadOnly && <span className='small'>{`v ${answerVersion + 1}`}</span>}
                            <IconContainer
                                height={"24px"}
                                color={answerVersion === (currentQuestion?.answers.length || 0) - 1 && "#babfc7"}
                                icon={ChevronRight}
                                onClick={handleNextVersion}
                                disabled={answerVersion === (currentQuestion?.answers.length || 0) - 1}
                            />
                        </div>
                    </div>
                </CustomAnswerTabs>
                <div id="answer-container" className='w-100 h-100'>
                    {memoizedTextAreaPane}
                    {memoizedCodingPane}
                    {memoizedSketchingPane}
                    {memoizedRecordPane}
                    {memoizedUploadPane}
                </div>
            </SplitPaneWrapper>
            {toggleType === "Feedback" && currentAnswer && !!currentAnswer.feedbacks.length && <div className='h-100'>
                <FeedbackComponent
                    ref={feedbackSketchingRef}
                    feedback={currentAnswer?.feedbacks[0]}
                    currentAnswer={currentAnswer}
                    currentQuestion={currentQuestion}
                    answerVersion={answerVersion}
                />
            </div>}
        </SplitPaneWrapper>
    );
};

export default AnswerTabContainer;
