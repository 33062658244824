import React from 'react';
import styled from 'styled-components';
import RichTextEditor from '../../../components/Common/Editors/RichTextEditor';
import { handleMarkedText, isPlacementTrack } from '../../../utilities/helperFunctions';
import { QuestionTitle, StyledQuestionDescription } from '../QuestionCodingPane.style';
import QuestionVideos from 'components/Question/QuestionField/Components/QuestionVideos';
import { withSecurityFeatures } from 'components/HigherOrderComponents/withSecurityFeatures';

interface QuestionDescriptionProps {
    question: {
        number?: number
        title: string;
        description: string;
        id: string
    };
}

const QuestionDescription: React.FC<QuestionDescriptionProps> = ({ question }) => (
    <StyledQuestionDescription>
        {question.number ?
            <QuestionTitle>{`${question.number}. ${question.title}`}</QuestionTitle> :
            <QuestionTitle>{`${question.title}`}</QuestionTitle>}
        {!isPlacementTrack() && <QuestionVideos splitView={true} questionId={question.id} />}
        <RichTextEditor
            id={'question-description-' + question.id}
            doNotAllowCopy
            disabled
            value={handleMarkedText(question.description)}
            customStyles={{ height: 'auto', resize: 'none', boxShadow: 'none' }}
        />
    </StyledQuestionDescription>
);

export default withSecurityFeatures(React.memo(QuestionDescription, (prev, next) => prev.question.id === next.question.id));
