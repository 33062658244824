import { FieldArray } from "formik";
import React from "react";
import { EXPERIENCE } from "types/OnboardCandidate";
import { PlusLg } from '@styled-icons/bootstrap/PlusLg';
import { IconButton, InputBase, TextareaAutosize } from "@material-ui/core";
import { Subtract } from "@styled-icons/remix-line/Subtract";
import { DefaultProjects } from "../OnboardCandidateContants";

type IProps = {
    exp: EXPERIENCE;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    formError: any;
    submitCount: number;
    parentName: string;
    parentIdx: number;
}

const Projects = ({
    exp,
    formError,
    setFieldValue,
    submitCount,
    parentName,
    parentIdx
}: IProps) => {
    return (
        <FieldArray name={`${parentName}.${parentIdx}.projects`}>
            {({ push: pushProject, remove: removeProject, name }) => {
                const projectError = formError?.experiences?.[parentIdx]?.projects;
                return (
                    <>
                        {exp.projects?.map((proj, idx) => {
                            return (<React.Fragment key={'projects_' + idx}>
                                <div className="row mb-2 align-items-center">
                                    <div className="col-11">
                                        <span className="text-muted small">Project {idx + 1}</span>
                                        <InputBase
                                            placeholder="Project tile"
                                            className="input-element mt-2"
                                            name='keyProject'
                                            value={proj.keyProject}
                                            onChange={(e) => setFieldValue(`${name}.${idx}.keyProject`, e.target.value)}
                                        />
                                        {typeof projectError?.[idx] === 'object' && projectError?.[idx]?.keyProject && submitCount > 0 &&
                                            <span className="text-danger error-font">{projectError?.[idx]?.keyProject}</span>}
                                    </div>
                                    <div className="col-1">
                                        <div className="d-flex align-items-end w-100 h-100">
                                            <IconButton style={{
                                                background: 'tomato'
                                            }}
                                                onClick={() => removeProject(idx)}
                                            >
                                                <Subtract width={'15px'} color="#ffffff" />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-11">
                                        <TextareaAutosize
                                            minRows={4}
                                            className="input-element mt-2"
                                            name='impact'
                                            value={proj.impact}
                                            onChange={(e) => setFieldValue(`${name}.${idx}.impact`, e.target.value)}
                                            placeholder="Key Impacts"
                                        />
                                        {typeof projectError?.[idx] === 'object' && projectError?.[idx]?.impact && submitCount > 0 &&
                                            <span className="text-danger error-font">{projectError?.[idx]?.impact}</span>}
                                    </div>
                                </div>
                            </React.Fragment>)
                        })}
                        <div className="row mb-4">
                            <div className="btn btn-link" onClick={() => pushProject({ ...DefaultProjects })}>
                                <PlusLg width={'15px'} className="mr-2" style={{ marginTop: '-4px' }} />
                                <span>Add a Project</span>
                            </div>
                        </div>
                    </>
                )
            }}
        </FieldArray>
    )
}

export default Projects;