import RichTextEditor from 'components/Common/Editors/RichTextEditor';
import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { Expert_Login, QUESTION_STATUS_TEXT } from 'utilities/constants';
import { Answer, IQuestion } from '../types';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store';
import { setTextAreaValue } from 'store/splitView';
import ReactQuill from 'react-quill';
import { isPlacementTrack } from 'utilities/helperFunctions';

interface ITextAreaPaneProps {
    currentQuestion: IQuestion;
    currentAnswer?: Answer;
    RTOnChange?: (value: string, oldValue: string, source: string) => void;
    answerVersion: number;
}

const TextAreaPane = forwardRef(({
    currentQuestion,
    currentAnswer,
    RTOnChange,
    answerVersion,
}: ITextAreaPaneProps, ref) => {
    const dispatch = useAppDispatch();
    const [textAreaHeight, setTextAreaHeight] = useState<string>("100%");
    const [textValue, setTextValue] = useState(currentAnswer?.answer?.answer || '');
    const isExpertLogin = getValueBrowserStorage(Expert_Login) !== null;
    const debouncedRTOnChange = useRef(
        _.debounce((value: string, oldValue: string, source: string) => {
            RTOnChange && RTOnChange(value, oldValue, source);
        }, 3000)
    ).current;

    const handleRTChange = (value: string, oldValue: string, source: string) => {
        setTextValue(value);
        debouncedRTOnChange(value, oldValue, source);
    };

    const calculateTextAreaHeight = () => {
        const textAreaElement = document.getElementById("answer-container");
        if (textAreaElement && textAreaElement.parentElement) {
            const parentElement = textAreaElement.parentElement;
            const height = parentElement.getBoundingClientRect().height;
            setTextAreaHeight(`${height - 100}px`);
        }
    }

    useImperativeHandle(ref, () => ({
        setEditorValue(value: string) {
            handleRTChange(value, "", "");
        }
      }));

    const isDisabled =
        isExpertLogin || (isPlacementTrack() && window.location.pathname !== "/onboardCandidate") ||
        (currentAnswer &&
            ((currentQuestion && currentQuestion.question && currentQuestion.question.status !== QUESTION_STATUS_TEXT.UNANSWERED ||
                answerVersion > 0) &&
                ((currentQuestion && currentQuestion.question && currentQuestion.question.status !== QUESTION_STATUS_TEXT.UNANSWERED &&
                    currentQuestion.question.status !== QUESTION_STATUS_TEXT.ANSWERED) ||
                    answerVersion > 0))) || !RTOnChange;

    const placeholder =
        currentQuestion?.question?.questionType === 'CONTENT'
            ? 'If you have any questions, please ask the expert'
            : 'Type your response here...';

    useEffect(() => {
        calculateTextAreaHeight();
    }, [])

    return (
        <div className='p-2'>
            <RichTextEditor
                placeholder={placeholder}
                value={textValue}
                disabled={isDisabled}
                onChange={(value: string, oldValue: string, source: string) => !isDisabled && handleRTChange(value, oldValue, source)}
                id={`rich-text-${currentQuestion?.question?._id || ""}`}
                data-testid="answerfield-textarea"
                customStyles={{ height: textAreaHeight, boxShadow: 'none' }}
            />
        </div>
    );
});

export default TextAreaPane;
