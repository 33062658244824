import { AnswerBottom, AnswerField, FeedbackField, MetricsField } from 'components';
import useStateRefInSync from 'components/Common/customHooks/useStateRefInSync';
import { useQuestions } from 'components/Question/useQuestions';
import { useGPTBot } from 'containers/GPTChatBotContainer/useGPTChatBot';
import { useLoader } from 'context/loaderDots';
import { useMessagePopup } from 'context/messagePopup';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveCandidateLastActivity } from 'store/evaluationPlatform';
import styled from 'styled-components';

import { extractTextFromHTML } from 'utilities/commonUtils';
import { answerType, API_TIMEOUT, DEFAULT_TOKEN, languages, QUESTION_STATUS_TEXT, TrackEnrollType } from 'utilities/constants';
import { canSubmitQuestionForReview, getEnrollmentType, getQuillContent } from 'utilities/helperFunctions';

const StyledAnswerSet = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    margin-bottom: 18px;
  }
`;

interface IProps {
  idx: number;
  candidateTrackId: string;
  capabilityId: string;
  questionId: string;
  answer: any;
  handleMaximizeContent?: any;
  setAnswer: any;
  saveResponseForQuestionOfCandidateTrack: any;
  isSaved: boolean;
  setIsSaved: any;
  questionStatusText: string;
  setQuestionStatusText: any;
  submitResponseToExpert: any;
  question: any;
  setFeedback: any;
  handleEdit: any;
  answerVersion: number;
  setQuestionId: Function,
  triggerFeedback: any;
  setTriggerFeedback: any;
  questionInfo?: any
  handleAnswerShare?: () => void;
  communityVersion?: boolean;
  isMeetingView?: boolean;
  expertName?: string;
  totalAnswerVersions: number;
  setMinimizeRecording?: Function;
  minimizedModelIndex?: string;
  currModelIndex?: string;
  openRecorder: string;
  setOpenRecorder: any;
  fullQuestion: any;
  chatWithExpert: any;
}

const getAnswerFeedbackStatus = (props: any): string => {
  if (props.answer === null || (
    props.answer &&
    !props.answer?.answer?.sketchAvailable &&
    props.answer?.answer?.answer === '' &&
    props.answer?.answer?.codeAnswer === '')) {
    return 'UNANSWERED';
  } else if ((props.answer?.answer?.sketchAvailable || props.answer?.answer?.answer !== '' || props.answer?.answer?.codeAnswer !== '') && props.answer?.feedbacks?.length === 0) {
    return 'ANSWERED';
  } else if (props.answer?.feedbacks && props.answer?.feedbacks[0]?.feedbackStatus === 'RESPONSE_IS_SUBMITTED_TO_EXPERT') {
    return 'SEND_FOR_REVIEW';
  } else if (props.answer?.feedbacks && props.answer?.feedbacks[0]?.feedbackStatus === 'EXPERT_REVIEWING_RESPONSE') {
    return 'UNDER_REVIEW';
  } else if (props.answer?.feedbacks && props.answer?.feedbacks[0]?.feedbackStatus === 'EXPERT_GIVES_FEEDBACK') {
    return 'FEEDBACK_RECEIVED';
  } else if (props.answer?.feedbacks && props.answer?.feedbacks[0]?.feedbackStatus === 'FEEDBACK_VIEWED_BY_CANDIDATE') {
    return 'FEEDBACK_VIEWED_BY_CANDIDATE';
  } else if (props.answer?.feedbacks && props.answer?.feedbacks[0]?.feedbackStatus === 'RESPONSE_IS_COMPLETED_AND_SUBMITTED') {
    return 'SEND_FOR_REVIEW';
  }
  return 'UNANSWERED';
};

export const AnswerSet: React.FC<IProps> = (props) => {
  const [answerStatus, setAnswerStatus] = useState<string>("");
  const [feedback, setFeedback] = useState<any>();
  const [expertModle, setExpertModel] = useState<any>(false);
  let hasNeedToSave: any = useRef(false);

  const saving = useSelector((state: any) => state.evaluationPlatform.saving);
  const { enqueueSnackbar } = useSnackbar();
  const [codeAns, updateCodeAns, codeAnsRef] = useStateRefInSync(props?.answer?.answer?.codeAnswer || '')
  const [codeType, updateCodeType, codeTypeRef] = useStateRefInSync(props?.answer?.answer?.codeType || languages[0])
  const [richText, updateRichText, richTextRef] = useStateRefInSync(props.answer?.answer?.answer || '')
  const [containRecording, setContainRecording] = useState<boolean | undefined>();
  const isAnswerChanged = useRef(false);
  const questionAnswerId = useRef(props.answer?.answer._id);
  const [hasSaveError, setHasSaveError] = useState(false);

  const loader = useLoader();
  const messagePopup = useMessagePopup();
  const { getExperts, getAnswersList, saveAndUpdateLastActivity } = useQuestions();
  const { showBot } = useGPTBot();

  useEffect(() => {
    let astatus = getAnswerFeedbackStatus(props);
    setAnswerStatus(astatus);
    let feedbackIdx = _.findIndex(props.answer?.feedbacks, (feedbackItem: any) => {
      return feedbackItem.questionAnswerId === props.answer?.answer?._id;
    });
    let fb = feedbackIdx > -1 && props.answer?.feedbacks[feedbackIdx];
    setFeedback(fb);
  }, [props.answer])

  const checkHasNeedToSave = () => {
    if (answerStatus === QUESTION_STATUS_TEXT.ANSWERED || answerStatus === QUESTION_STATUS_TEXT.UNANSWERED) {
      if (props?.question?.answerType === answerType.CODE) {
        if (props.answer?.answer?.codeAnswer && (props.answer?.answer?.codeType !== codeType)) {
          hasNeedToSave.current = true;
          return;
        }
        if (codeAnsRef.current.trim() === '') {
          updateCodeAns('')
          if (props.answer && props.answer?.answer?.codeAnswer !== '') {
            hasNeedToSave.current = true;
            return
          }
        }
        else if (codeAnsRef.current !== props.answer?.answer?.codeAnswer) {
          hasNeedToSave.current = true;
          return;
        }
      }

      if (getQuillContent(richTextRef.current) === '') {
        updateRichText('')
        if (props.answer && props.answer?.answer?.answer !== '')
          hasNeedToSave.current = true
        else
          hasNeedToSave.current = false;
        return;
      }

      if (props.answer === null && getQuillContent(richTextRef.current) !== '') {
        hasNeedToSave.current = true;
        return;
      }
      if (props.answer && props.answer?.answer?.answer !== richTextRef.current) {
        hasNeedToSave.current = true
      }
    }
    else {
      hasNeedToSave.current = false;
    }
  };

  const handleSaveQuestion = (nextProps?: IProps, removeSavingCheck?: true, cb?: Function) => {
    const localProps = nextProps ?? props;
    const capabilityIds: string[] =
      localProps.answer?.answer && localProps.answer.answer?.capabilityIds ? [...localProps.answer.answer?.capabilityIds] : [];
    if (capabilityIds.indexOf(localProps.capabilityId) < 0) capabilityIds.push(localProps.capabilityId);
    if (saving && !removeSavingCheck) {
      return;
    }
    const payload: any = {
      token: DEFAULT_TOKEN,
      candidateTrackId: localProps.candidateTrackId,
      capabilityIds,
      questionId: localProps.questionId,
      answer: richTextRef.current,
      codeAnswer: codeAnsRef.current,
      codeType: codeTypeRef.current || undefined,
      containRecording
    };
    let isUpdate = false;

    if (localProps.answer) {
      isUpdate = true;
      payload.questionAnswerId = localProps.answer.answer._id;
    }
    localProps.setQuestionStatusText('Saving...');
    if (isAnswerChanged.current || window.location.pathname !== '/question') {
      isAnswerChanged.current = false;
      localProps
        .saveResponseForQuestionOfCandidateTrack(payload)
        ?.then((res: any) => {
          if (res.payload) {
            questionAnswerId.current = res.payload.output.questionAnswerId;
            hasNeedToSave.current = false;
            localProps.setIsSaved(true);
            const answer = res.payload.output;
            const data = {
              _id: answer.questionAnswerId,
              questionId: answer.questionId,
              answer: answer.answer,
              capabilityIds,
              codeAnswer: answer.codeAnswer,
              codeType: answer.codeType,
              candidateTrackId: answer.candidateTrackId,
              updateAt: new Date(answer.updateAt).toUTCString(),
              sketchAvailable: answer.sketchAvailable
            };
            localProps.setAnswer({
              capabilities: localProps.question.capabilities,
              questionId: localProps.questionId,
              answer: data,
              isUpdate,
            });
          } else {
            !removeSavingCheck && enqueueSnackbar(res.error?.message, {
              variant: 'error',
              autoHideDuration: 2500,
            });
            setHasSaveError(true);
          }
        })
        .catch((err: any) => {
          !removeSavingCheck && enqueueSnackbar(err?.message, {
            variant: 'error',
            autoHideDuration: 2500,
          });
          hasNeedToSave.current = false
          setHasSaveError(true);
        })
        .finally(() => {
          cb && cb();
        });
    } else {
      cb && cb();
    }
  };

  const saveCandidateAnswer = (cb: Function) => {
    checkHasNeedToSave();
    if (hasNeedToSave.current) {
      setHasSaveError(false);
      props.setIsSaved(false);
      handleSaveQuestion(props, undefined, cb);
    } else {
      cb();
    }
  }

  const submitFeedbackToExpert = (expertId: string) => {
    loader.showLoader();
    const capabilityIds: string[] =
      props.answer?.answer && props.answer.answer?.capabilityIds ? [...props.answer.answer.capabilityIds] : [];
    if (capabilityIds.indexOf(props.capabilityId) < 0) capabilityIds.push(props.capabilityId);
    const payload = {
      token: DEFAULT_TOKEN,
      candidateTrackId: props.candidateTrackId,
      capabilityIds,
      questionId: props.questionId,
      questionAnswerId: props.answer?.answer._id ? props.answer?.answer._id : questionAnswerId.current,
      expertId: expertId,
    };
    props
      .submitResponseToExpert(payload)
      .then((res: any) => {
        if (res.payload) {
          props.setFeedback({
            ...res.payload.output,
            expertId,
            capabilities: props.question?.capabilities,
          });
          props.setQuestionStatusText('Submitted for Review');
          messagePopup.success('Your response is submitted');
        } else {
          messagePopup.fail('Submission failed. Please try again.');
        }
        loader.hideLoader();
      })
      .catch((err: any) => {
        if (err.message === API_TIMEOUT.message) {
          messagePopup.fail('Request timeout! Submission aborted.');
        } else messagePopup.fail('Submission failed. Please try again.');
        loader.hideLoader();
      },
      );
  };

  const getChatQuestion = () => {
    const rtTexts = richTextRef.current
    let question = props.question.title;
    if (rtTexts) {
      question += " <b>\n\nMy Answer : \n</b>" + rtTexts;
    }
    if (codeAnsRef.current) {
      question += " <b>\n\nMy Code :\n</b>" + codeAnsRef.current
    }
    return question;
  }

  const getChatPrompts = () => {
    if (richTextRef.current || codeAnsRef.current) {
      return ["Can you suggest ways to improve?"];
    }
    return ["Can you provide answer for this question?"];
  }

  const handleAiChatBtn = () => {
    const chatQuestion = getChatQuestion();
    const chatPrompts = getChatPrompts();
    saveAndUpdateLastActivity({
      selectedCapabilityId: props.capabilityId,
      currentQuestionId: props.questionId,
    });
    showBot(props.question.title, false, chatPrompts, chatQuestion);
  }

  const handleSelectExpert = () => {
    saveCandidateAnswer(() => {
      if (!canSubmitQuestionForReview(props.question)) {
        submitFeedbackToExpert('');
        setExpertModel(false);
        return;
      }
      if (getEnrollmentType() === TrackEnrollType.FORPLACEMENT) {
        let answers = getAnswersList(props.questionId, props.capabilityId)?.answers;
        if (answers.length > 1) {
          let lstFeedbackExpertId = answers[1].feedbacks[0].expertId;
          submitFeedbackToExpert(lstFeedbackExpertId);
        } else {
          let expertList = getExperts();
          if (expertList?.length > 0) {
            const previouseExpert = answers[0]?.answer?.lastSubmissionExpertId;
            let randomisedExpert = previouseExpert ? previouseExpert : expertList[Math.floor(Math.random() * expertList.length)]?._id;
            submitFeedbackToExpert(randomisedExpert);
            setExpertModel(false);
            return;
          } else {
            enqueueSnackbar('Expert id not found.', {
              variant: 'error',
              autoHideDuration: 2500,
            });
          }
        }
      }
      setExpertModel(true);
    });
  };

  return (
    <StyledAnswerSet>
      <AnswerField
        questionInfo={props.questionInfo}
        key={`${props.answerVersion}`}
        textId={`${props.questionId}-${props.idx}`}
        handleMaximizeContent={props.handleMaximizeContent}
        candidateTrackId={props.candidateTrackId}
        capabilityId={props.capabilityId}
        questionId={props.questionId}
        answer={props.answer}
        setAnswer={props.setAnswer}
        saveResponseForQuestionOfCandidateTrack={props.saveResponseForQuestionOfCandidateTrack}
        isSaved={props.isSaved}
        setIsSaved={props.setIsSaved}
        questionStatusText={props.questionStatusText}
        setQuestionStatusText={props.setQuestionStatusText}
        question={props.question}
        answerStatus={answerStatus}
        answerVersion={props.answerVersion}
        totalAnswerVersions={props.totalAnswerVersions}
        setQuestionId={props.setQuestionId}
        handleAnswerShare={props.handleAnswerShare}
        isMeetingView={props.isMeetingView}
        setMinimizeRecording={props.setMinimizeRecording}
        minimizedModelIndex={props.minimizedModelIndex}
        currModelIndex={props.currModelIndex}
        openRecorder={props.openRecorder}
        fullQuestion={props.fullQuestion}
        setOpenRecorder={props.setOpenRecorder}
        communityVersion={props.communityVersion}
        hasNeedToSave={hasNeedToSave}
        checkHasNeedToSave={checkHasNeedToSave}
        codeAns={codeAns}
        codeType={codeType}
        containRecording={containRecording}
        handleSaveQuestion={handleSaveQuestion}
        hasSaveError={hasSaveError}
        isAnswerChanged={isAnswerChanged}
        richText={richText}
        richTextRef={richTextRef}
        setContainRecording={setContainRecording}
        setHasSaveError={setHasSaveError}
        updateCodeAns={updateCodeAns}
        updateCodeType={updateCodeType}
        updateRichText={updateRichText}
      />
      {feedback && <FeedbackField
        candidateTrackId={props.candidateTrackId}
        questionTitle={props.question?.title}
        expertName={props.expertName}
        feedback={feedback} />}
      {feedback && (
        <MetricsField feedback={feedback} capabilityId={props.capabilityId} isMeetingView={props.isMeetingView} />
      )}

      {!props.communityVersion && !props.isMeetingView &&
        <AnswerBottom
          id={`${props.questionId}-${props.idx}`}
          candidateTrackId={props.candidateTrackId}
          capabilityId={props.capabilityId}
          questionId={props.questionId}
          answer={props.answer}
          questionStatusText={props.questionStatusText}
          setQuestionStatusText={props.setQuestionStatusText}
          fullQuestion={props.fullQuestion}
          submitResponseToExpert={props.submitResponseToExpert}
          question={props.question}
          setFeedback={props.setFeedback}
          handleEdit={props.handleEdit}
          answerStatus={answerStatus}
          triggerFeedback={props.triggerFeedback}
          setTriggerFeedback={props.setTriggerFeedback}
          hasNeedToSave={hasNeedToSave}
          setExpertModel={setExpertModel}
          expertModle={expertModle}
          handleSelectExpert={handleSelectExpert}
          submitFeedbackToExpert={submitFeedbackToExpert}
          handleAiChatBtn={handleAiChatBtn}
          answerVersion={props.answerVersion}
        />}
    </StyledAnswerSet>
  );
};