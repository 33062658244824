import { Dashboard } from '@styled-icons/boxicons-solid/Dashboard';
import { ChalkboardTeacher } from "@styled-icons/fa-solid/ChalkboardTeacher";
import { PeopleArrows } from "@styled-icons/fa-solid/PeopleArrows";
import { Suitcase } from "@styled-icons/fa-solid/Suitcase";
import { PeopleCommunity } from "@styled-icons/fluentui-system-filled/PeopleCommunity";
import { DocumentPerson } from "@styled-icons/fluentui-system-regular/DocumentPerson";
import { Settings } from '@styled-icons/material/Settings';
import { Googleclassroom } from "@styled-icons/simple-icons/Googleclassroom";
import { Briefcase } from '@styled-icons/boxicons-regular/Briefcase';
import { IconButton } from 'components';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { RootState } from 'store';
import { getNotificationMsg } from "store/evaluationPlatform";
import styled from 'styled-components';
import { ListIcon } from 'theme/icons';
import { notEmpty } from 'utilities';
import { Candidate_Id, Expert_Login, Is_Hiring_Manager, MENUS, PlanType, RoleType, SIDEBAR_WIDTH } from 'utilities/constants';
import { isPlacementTrack } from "utilities/helperFunctions";

enum ActiveButtonStates {
  DASHBOARD = 1,
  QUESTION = 2,
  VIDEOS = 3,
  SETTINGS = 4,
  COMMUNITY = 5,
  CONSULTANCY = 6,
  RESUMEREVIEW = 7,
  HIRINGECOSYSTEM = 8,
  CLASSES = 9,
  ONBOARDTRACKS = 10
}

const StyledDashboardIcon = styled(Dashboard)`
  width: 35px;
  height: auto;
`;

const StyledSettings = styled(Settings)`
  width: 38px;
  height: auto;
`;

const StyledSidebar = styled.div<any>`
  left: 0;
  z-index: 10;
  display: ${(props) => (props.isMaximizeContent ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.isMaximizeContent ? '0px' : SIDEBAR_WIDTH + 'px')};
  transition: 1s;
  background: #dbdbdb;
  height: 100%;
  div {
    background: #dbdbdb;
  }
  .profileIcon {
    margin-top: 32px;
  }
  .profileIcon div {
    background: white;
    border-radius: 50%;
    width: 43px;
    height: 43px;
  }
  .dashboardText {
    font-size: 12px;
  }
  .consultancyIcon {
    margin-top: 58px;
  }
  .mt-6 {
    margin-top: 4.5rem;
  }
  & a:hover path {
    fill: ${(props) => (!props.disable ? '#5b94e3 ' : 'initial')};
  }
  
  & a:hover .iconText {
    color: ${(props) => (!props.disable ? '#5b94e3 ' : 'initial')};
  }
  & a {
    text-decoration: none;
    color: black;
    pointer: ${(props) => (props.disable ? 'none' : 'pointer')};
    pointer-events: ${(props) => (props.disable ? 'none' : 'unset')};
  }
`;

interface Props {
  className?: string;
  isMaximizeContent?: boolean;
  disable?: boolean;
  isMobileView?: boolean;
}

const TopIconButtonGroup = styled.div`
  overflow-y: auto;
  & a {
    text-decoration: none;
  }
`;

// const ProfileButton = () => (
//   <div className="profileIcon">
//     <div />
//   </div>
// );

const DashboardButton = (props: any) => {
  const isHiringManager = getValueBrowserStorage(Is_Hiring_Manager) === 'true';
  const content = (
    <div className="mb-3">
      <IconButton icon={StyledDashboardIcon} iconText="Dashboard" disable={props.disable} fill={props.color} badgeContent={props.msg}
        showNotification={props.showNotification} getNotificationMsgFromAPI={props.getNotificationMsgFromAPI} menu={MENUS.DASHBOARD} />
    </div>
  )
  if (isHiringManager) {
    return <Link to={'/dashboard/score'} onClick={() => props.setActiveButton(ActiveButtonStates.DASHBOARD)}>{content}</Link>
  }
  return props.disable ? content : <Link to={props.disable ? '#' : '/dashboard'} onClick={() => props.setActiveButton(ActiveButtonStates.DASHBOARD)}>{content}</Link>
};

const TracksButton = (props: any) => {
  let iconText = isPlacementTrack() ? "Questions" : "Track";
  const content = (
    <div className="mb-3">
      <IconButton icon={ListIcon} iconText={iconText} disable={props.disable} fill={props.color} badgeContent={props.msg}
        showNotification={props.showNotification} getNotificationMsgFromAPI={props.getNotificationMsgFromAPI} menu={MENUS.TRACK} />
    </div>
  );

  return props.disable ? content : <Link to={props.disable ? '#' : '/question'} onClick={() => props.setActiveButton(ActiveButtonStates.QUESTION)}>{content}</Link>
};

const OnboardTracks = (props: any) => {
  let iconText = 'Jobs';
  const content = (
    <div className="mb-3">
      <IconButton icon={Briefcase} iconText={iconText} disable={props.disable} fill={props.color} badgeContent={props.msg}
        showNotification={props.showNotification} getNotificationMsgFromAPI={props.getNotificationMsgFromAPI} menu={MENUS.ONBOARD_TRACKS} />
    </div>
  );

  return props.disable ? content : <Link to={props.disable ? '#' : '/onboardTracks'} onClick={() => props.setActiveButton(ActiveButtonStates.ONBOARDTRACKS)}>{content}</Link>
};

const TalkToExpertButton = (props: any) => {
  const content = (
    <div className="mb-3">
      <IconButton badgeContent={props.msg} icon={ChalkboardTeacher} iconText="Mock & Coaching" disable={props.disable} fill={props.color}
        showNotification={props.showNotification} getNotificationMsgFromAPI={props.getNotificationMsgFromAPI} menu={MENUS.MOCK_AND_COACHING} />
    </div>
  )

  return props.disable ? content : <Link to={props.disable ? '#' : '/meetings'} onClick={() => props.setActiveButton(ActiveButtonStates.VIDEOS)}>{content}</Link>
};

const CareerConsultancyButton = (props: any) => {
  const content = (
    <div className="mb-3">
      <IconButton icon={PeopleArrows} iconText="Career Consultancy" disable={props.disable} fill={props.color} subText={props.subText}
        showNotification={props.showNotification} getNotificationMsgFromAPI={props.getNotificationMsgFromAPI} menu={MENUS.CAREER_CONSULTANCY}
        badgeContent={props.msg} />
    </div>
  )

  return props.disable ? content : <Link to={props.disable ? '#' : '/consultancy'} onClick={() => props.setActiveButton(ActiveButtonStates.CONSULTANCY)}>{content}</Link>
};

const CommunityButton = (props: any) => {
  const content = (
    <div className="d-flex justify-content-center py-4 px-1">
      <IconButton icon={PeopleCommunity} iconText="Community" disable={props.disable} fill={props.color}
        showNotification={props.showNotification} getNotificationMsgFromAPI={props.getNotificationMsgFromAPI} menu={MENUS.COMMUNITY}
        badgeContent={props.msg} />
    </div>
  )

  return props.disable ? content : <Link to={props.disable ? '#' : '/community'} onClick={() => props.setActiveButton(ActiveButtonStates.COMMUNITY)}>{content}</Link>
};

const SettingsButton = (props: any) => {
  const content = (
    <div className="mb-4">
      <IconButton icon={StyledSettings} disable={props.disable} iconText="Manage Tracks" fill={props.color}
        showNotification={props.showNotification} getNotificationMsgFromAPI={props.getNotificationMsgFromAPI} menu={MENUS.SETTINGS}
        badgeContent={props.msg} />
    </div>
  )
  return props.disable ? content : <Link to={props.trackStatus ? '/settings/tracks' : '/settings'} onClick={() => props.setActiveButton(ActiveButtonStates.SETTINGS)}>{content}</Link>
};

const ProfileReview = (props: any) => {
  const content = (
    <div className="d-flex justify-content-center">
      <IconButton icon={DocumentPerson} iconText="My Profile" disable={props.disable} fill={props.color}
        showNotification={props.showNotification} getNotificationMsgFromAPI={props.getNotificationMsgFromAPI} menu={MENUS.RESUME_SERVICES}
        badgeContent={props.msg} />
    </div>
  )

  return props.disable ? content : <Link to={props.disable ? '#' : '/profile-review'} onClick={() => props.setActiveButton(ActiveButtonStates.RESUMEREVIEW)}>{content}</Link>
}

const HiringEcoSystem = (props: any) => {
  const content = (
    <div className="d-flex justify-content-center">
      <IconButton icon={Suitcase} iconText="Hiring Ecosystem" disable={props.disable} fill={props.color}
        showNotification={props.showNotification} getNotificationMsgFromAPI={props.getNotificationMsgFromAPI} menu={MENUS.HIRING_ECOSYSTEM}
        badgeContent={props.msg} />
    </div>
  )

  return props.disable ? content : <Link to={props.disable ? '#' : '/hiring-ecosystem'} onClick={() => props.setActiveButton(ActiveButtonStates.HIRINGECOSYSTEM)}>{content}</Link>
}

const Classes = (props: any) => {
  const content = (
    <div className="d-flex justify-content-center mb-3">
      <IconButton icon={Googleclassroom} iconText="Classes" disable={props.disable} fill={props.color} />
    </div>
  )

  return props.disable ? content : <Link to={props.disable ? '#' : '/classes'} onClick={() => props.setActiveButton(ActiveButtonStates.CLASSES)}>{content}</Link>
}

export const Sidebar: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState<ActiveButtonStates>(ActiveButtonStates.SETTINGS);
  const { trackId, trackPlan } = useSelector((state: RootState) => state.payment);
  const { badges } = useSelector((state: RootState) => state.evaluationPlatform);
  const profileCompleted = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidate?.profileCompleted);
  const enrollmentType = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack[0]?.trackEnrollType);
  const expertLogin = (getValueBrowserStorage(Expert_Login) == null) ? false : true;
  const isExpertRole = getValueBrowserStorage(RoleType) === 'expert';
  const _queryParams: any = queryString.parse(location.search);

  useEffect(() => {
    setActiveButtonState();
  }, [trackId, location.pathname]);

  const setActiveButtonState = () => {
    const pathName = location.pathname;
    if (pathName.includes('/dashboard')) {
      setActiveButton(ActiveButtonStates.DASHBOARD);
      return;
    }
    if (pathName.includes('/question')) {
      setActiveButton(ActiveButtonStates.QUESTION);
      return;
    }
    if (pathName.includes('/meetings')) {
      setActiveButton(ActiveButtonStates.VIDEOS);
      return;
    }
    if (pathName.includes('/settings')) {
      setActiveButton(ActiveButtonStates.SETTINGS);
      return;
    }
    if (pathName.includes('/community')) {
      setActiveButton(ActiveButtonStates.COMMUNITY);
      return;
    }
    if (pathName.includes('/consultancy')) {
      setActiveButton(ActiveButtonStates.CONSULTANCY);
      return;
    }
    if (pathName.includes('/profile-review')) {
      setActiveButton(ActiveButtonStates.RESUMEREVIEW);
      return;
    }
    if (pathName.includes('/hiring-ecosystem')) {
      setActiveButton(ActiveButtonStates.HIRINGECOSYSTEM);
      return;
    }
    if (pathName.includes('/classes')) {
      setActiveButton(ActiveButtonStates.CLASSES);
      return;
    }
    if (pathName.includes('/onboardTracks') || pathName.includes('/detailedOnboardTrackView')) {
      setActiveButton(ActiveButtonStates.ONBOARDTRACKS);
      return;
    }
  }

  const getActiveColor = (state: ActiveButtonStates): string => {
    return activeButton === state ? '#5b94e3' : 'default';
  }

  const getNotificationStatus = (fieldName: string): boolean => {
    if (badges?.notifications?.[fieldName]) {
      return badges.notifications[fieldName]['status'];
    }
    return false;
  }

  const getNotificationMsgFromAPI = async (menu: string = MENUS.ALL) => {
    const candidateId = getValueBrowserStorage(Candidate_Id);
    if (candidateId && trackId) {
      dispatch(getNotificationMsg({ trackId, menu }));
    }
  }

  const getTxtNotificationMsg = (menu: string): any => {
    if (badges?.notifications?.[menu]?.message?.length > 0) {
      const messages: string[] = badges.notifications[menu].message;
      let txtMsg = (
        <div>
          {messages.map((msg, idx) => {
            return (<div key={idx + msg}>{(idx + 1) + ". " + msg}</div>)
          })}
        </div>
      );
      return txtMsg;
    }
    return '';
  }

  const sideBarBtnDisabled = () => {
    if (expertLogin) {
      return !notEmpty(trackId);
    }
    if (!profileCompleted) {
      return true;
    }
    return !notEmpty(trackId);
  }

  const showSideBarMenu = () => {
    if (_queryParams?.jobId) {
      return false;
    }
    return true;
  }

  return (
    <StyledSidebar {...props} className={`${enrollmentType ? '' : 'justify-content-end'}`}>
      {enrollmentType && <TopIconButtonGroup className='d-flex flex-column align-items-center justify-content-start flex-grow-1'>
        {/* <ProfileButton /> */}
        {showSideBarMenu() && <>
          <DashboardButton
            setActiveButton={setActiveButton}
            disable={sideBarBtnDisabled()}
            msg={getTxtNotificationMsg(MENUS.DASHBOARD)}
            color={getActiveColor(ActiveButtonStates.DASHBOARD)}
            showNotification={getNotificationStatus(MENUS.DASHBOARD)}
            getNotificationMsgFromAPI={getNotificationMsgFromAPI} />
          <TracksButton
            setActiveButton={setActiveButton}
            disable={sideBarBtnDisabled()}
            color={getActiveColor(ActiveButtonStates.QUESTION)}
            msg={getTxtNotificationMsg(MENUS.TRACK)}
            trackPlan={trackPlan}
            showNotification={getNotificationStatus(MENUS.TRACK)}
            getNotificationMsgFromAPI={getNotificationMsgFromAPI} />
          {!isPlacementTrack() && (
            <>
              {/* {!expertLogin && (
                <Classes
                  setActiveButton={setActiveButton}
                  disable={sideBarBtnDisabled()} color={getActiveColor(ActiveButtonStates.CLASSES)} />
              )} */}
              {(!expertLogin || isExpertRole) && (
                <TalkToExpertButton
                  setActiveButton={setActiveButton}
                  disable={(expertLogin && !isExpertRole) || sideBarBtnDisabled()}
                  msg={getTxtNotificationMsg(MENUS.MOCK_AND_COACHING)}
                  color={getActiveColor(ActiveButtonStates.VIDEOS)}
                  showNotification={getNotificationStatus(MENUS.MOCK_AND_COACHING)}
                  getNotificationMsgFromAPI={getNotificationMsgFromAPI} />
              )}
              {/* {!expertLogin && (
                <CareerConsultancyButton
                  setActiveButton={setActiveButton}
                  getNotificationMsgFromAPI={getNotificationMsgFromAPI}
                  msg={getTxtNotificationMsg(MENUS.CAREER_CONSULTANCY)}
                  showNotification={getNotificationStatus(MENUS.CAREER_CONSULTANCY)}
                  disable={sideBarBtnDisabled()}
                  color={getActiveColor(ActiveButtonStates.CONSULTANCY)}
                  subText={trackPlan === PlanType.Free && 'Free'} />
              )} */}
            </>
          )}
          {
            <>
              {(!expertLogin) && (
                <OnboardTracks
                  setActiveButton={setActiveButton}
                  disable={sideBarBtnDisabled()}
                  color={getActiveColor(ActiveButtonStates.ONBOARDTRACKS)}
                  msg={getTxtNotificationMsg(MENUS.ONBOARD_TRACKS)}
                  trackPlan={trackPlan}
                  showNotification={getNotificationStatus(MENUS.ONBOARD_TRACKS)}
                  getNotificationMsgFromAPI={getNotificationMsgFromAPI} />
              )}
            </>
          }
          <ProfileReview
            setActiveButton={setActiveButton}
            showNotification={getNotificationStatus(MENUS.RESUME_SERVICES)}
            getNotificationMsgFromAPI={getNotificationMsgFromAPI}
            msg={getTxtNotificationMsg(MENUS.RESUME_SERVICES)}
            color={getActiveColor(ActiveButtonStates.RESUMEREVIEW)}
          />

          {/* {!isPlacementTrack() && !expertLogin && (
            <CommunityButton
              setActiveButton={setActiveButton}
              disable={sideBarBtnDisabled()}
              color={getActiveColor(ActiveButtonStates.COMMUNITY)}
              msg={getTxtNotificationMsg(MENUS.COMMUNITY)}
              showNotification={getNotificationStatus(MENUS.COMMUNITY)}
              getNotificationMsgFromAPI={getNotificationMsgFromAPI} />
          )} */}
          {/* {!expertLogin && (
            <HiringEcoSystem
              setActiveButton={setActiveButton}
              disable={sideBarBtnDisabled()}
              color={getActiveColor(ActiveButtonStates.HIRINGECOSYSTEM)}
              msg={getTxtNotificationMsg(MENUS.HIRING_ECOSYSTEM)}
              showNotification={getNotificationStatus(MENUS.HIRING_ECOSYSTEM)}
              getNotificationMsgFromAPI={getNotificationMsgFromAPI} />
          )} */}
        </>}
      </TopIconButtonGroup>}
      {!expertLogin && (
        <SettingsButton
          setActiveButton={setActiveButton}
          trackStatus={!notEmpty(trackId)}
          disable={sideBarBtnDisabled()}
          color={getActiveColor(ActiveButtonStates.SETTINGS)}
          msg={getTxtNotificationMsg(MENUS.SETTINGS)}
          showNotification={getNotificationStatus(MENUS.SETTINGS)}
          getNotificationMsgFromAPI={getNotificationMsgFromAPI} />
      )}
    </StyledSidebar>
  );
};
