import { IconButton, InputBase, MenuItem, Select } from '@material-ui/core';
import { FieldArray } from 'formik'
import React from 'react'
import { CandidateExperienceExpertiseType } from 'types/OnboardCandidate';
import { DefaultExpertise } from '../OnboardCandidateContants';
import { PlusLg } from '@styled-icons/bootstrap/PlusLg';
import { Subtract } from "@styled-icons/remix-line/Subtract";

type IProps = {
    values: CandidateExperienceExpertiseType,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    formError: any;
    submitCount: number;
}

const Expertise = ({
    values,
    formError,
    setFieldValue,
    submitCount
}: IProps) => {
    return (
        <FieldArray name="expertises" >
            {({ push, remove, name }) => (
                <>
                    {values.expertises?.map((expe, idx) => {
                        return (
                            <div className="row mb-2">
                                <div className="col-9">
                                    <InputBase
                                        placeholder="Skill Name"
                                        className="input-element mt-2"
                                        name='skill'
                                        value={expe.skill}
                                        onChange={(e) => setFieldValue(`${name}.${idx}.skill`, e.target.value)}
                                    />
                                    {typeof formError.expertises?.[idx] === 'object' && formError.expertises?.[idx]?.skill && submitCount > 0 &&
                                        <span className="text-danger error-font">{formError.expertises?.[idx]?.skill}</span>}
                                </div>
                                <div className="col-2">
                                    <Select
                                        variant="outlined"
                                        placeholder="Select Currency"
                                        value={expe.rating}
                                        onChange={(e, val) => setFieldValue(`${name}.${idx}.rating`, e.target.value)}
                                        className="input-element-select small-input-element mt-2"
                                    >
                                        {[...Array(11).keys()].map((rating, idx) => {
                                            return (
                                                <MenuItem
                                                    value={rating}
                                                    key={idx + "_rating"}
                                                >
                                                    {rating}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {typeof formError.expertises?.[idx] === 'object' && formError.expertises?.[idx]?.rating && submitCount > 0 &&
                                        <span className="text-danger error-font">{formError.expertises?.[idx]?.rating}</span>}
                                </div>
                                <div className="col-1">
                                    <div className="d-flex align-items-end w-100 h-100">
                                        <IconButton style={{
                                            background: 'tomato'
                                        }}
                                            onClick={() => remove(idx)}
                                        >
                                            <Subtract width={'15px'} color="#ffffff" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="row mb-4">
                        <div className="btn btn-link" onClick={() => push({ ...DefaultExpertise })}>
                            <PlusLg width={'15px'} className="mr-2" style={{ marginTop: '-4px' }} />
                            <span>Add a Skill</span>
                        </div>
                    </div>
                </>
            )}
        </FieldArray>
    )
}

export default Expertise;