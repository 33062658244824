import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { debounce, isNumber } from "lodash";
import { useSnackbar } from "notistack";
import CandidateProfile from "pages/OnboardCandidate/CandidateProfile";
import CandidateExperienceExpertise from "pages/OnboardCandidate/ExperienceExpertise/CandidateExperienceExpertise";
import JobStepsContainer from "pages/OnboardCandidate/JobStepsContainer";
import ResumeUpload from "pages/OnboardCandidate/ResumeUpload";
import { getFormatLocationForSave } from "pages/ProfileReview/ProfileReview.util";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { evaluationPlatformService } from "services";
import { getValueBrowserStorage } from "services/browserStorageService";
import { RootState } from "store";
import { setOnboardCandidateProfile } from "store/evaluationPlatform";
import { CandidateExperienceExpertiseType, CandidateProfileType, ResumeData } from "types/OnboardCandidate";
import { Candidate_Id } from "utilities/constants";

const MAX_STEP = 2;

const Profile = (props: {
    handleProfileCompleted: () => void;
    hideMakeAPitch?: boolean;
    defaultProfileStep?: number;
}) => {
    const candidateId = getValueBrowserStorage(Candidate_Id) ?? "";
    const candidateProfileData = useSelector((state: RootState) => state.evaluationPlatform.candidateProfileOnboard);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [completedStep, setCompletedStep] = useState<number>(-1);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [autoSaveLoaderStep, setAutoSaveLoaderStep] = useState<number>(-1);
    const candidateProfileSubmitBtnRef = useRef<HTMLInputElement | null>(null);
    const candidateExpertiseSubmitBtnRef = useRef<HTMLInputElement | null>(null);
    const firstTimeStepAutoMoved = useRef<boolean>(false);
    const debounceSave = useCallback(debounce(
        (value: CandidateProfileType) => { 
            saveCurrentStepDetails(value, () => { setAutoSaveLoaderStep(-1) }, () => { setAutoSaveLoaderStep(-1) });
         },
        400), []);

    const handleProfileDataChange = (value: CandidateProfileType | CandidateExperienceExpertiseType, autoSave?: boolean) => {
        const newValue = { ...candidateProfileData, ...value };
        dispatch(setOnboardCandidateProfile(newValue));

        if (autoSave) {
            setAutoSaveLoaderStep(currentStep);
            debounceSave(newValue);
        }
    }

    const handleAfterResumeParse = (intervalId: NodeJS.Timeout | null) => {
        setCompletedStep((prev) => Math.max(currentStep, prev));
        setCurrentStep(1);
        setLoading(false);
        intervalId && clearInterval(intervalId);
    }

    const pollResumeParsing = () => {
        let maxCallCount = 15;
        setLoading(true);
        const intervalId = setInterval(() => {
            evaluationPlatformService.getResumeParsingStatus(candidateId)
                .then((res) => {
                    if (maxCallCount === 0) {
                        handleAfterResumeParse(intervalId);
                    } else if (res.output.resumeParsingCompleted) {
                        clearInterval(intervalId);
                        evaluationPlatformService.getCandidateProfile(candidateId)
                            .then(res => {
                                handleProfileDataChange({ ...candidateProfileData, ...res.output });
                                handleAfterResumeParse(intervalId);
                            })
                            .catch((e) => {
                                enqueueSnackbar('Error occurred while parsing the resume please try again!', { variant: 'error', autoHideDuration: 2500 });
                                setLoading(false);
                            })
                    }
                    maxCallCount--;
                })
                .catch((e) => {
                    enqueueSnackbar('Error occurred while parsing the resume please try again!', { variant: 'error', autoHideDuration: 2500 });
                    setLoading(false);
                })
        }, 2000);
    }

    const handleResumeSelect = (value: ResumeData, resumeUpdated: boolean) => {
        if (resumeUpdated) {
            handleProfileDataChange({ ...candidateProfileData, resumeUrl: value.url });
            setLoading(true);
            evaluationPlatformService.updateCandidateResume(value.url, candidateId, 'PROFILE_DETAILS')
                .then((res) => {
                    pollResumeParsing();
                })
                .catch((e) => {
                    enqueueSnackbar('Error occurred while parsing the resume please try again!', { variant: 'error', autoHideDuration: 2500 });
                    setLoading(false);
                })
        } else {
            handleAfterResumeParse(null);
        }
    }

    const handleNextClick = () => {
        firstTimeStepAutoMoved.current = true;      // do not allow step change automatically once step was changed with btn
        if (currentStep === 1) {
            candidateProfileSubmitBtnRef.current?.click();
        } else if (currentStep === 2) {
            candidateExpertiseSubmitBtnRef.current?.click();
        }
    }

    const handlePrevClick = () => {
        firstTimeStepAutoMoved.current = true;      // do not allow step change automatically once step was changed with btn
        setCurrentStep((prev) => prev - 1);
    }

    const saveCurrentStepDetails = (value: CandidateProfileType, cb: Function, failCb: Function) => {
        const candidateProfile: CandidateProfileType = {
            ...candidateProfileData,
            ...value,
            candidateId,
        }
        candidateProfile['locations'] = getFormatLocationForSave(candidateProfile.selectedLocations);
        evaluationPlatformService.updateCandidateProfilePlacement(candidateProfile)
            .then((res) => {
                cb();
            })
            .catch(() => {
                enqueueSnackbar('Error occurred while saving your profile. please try again!', { variant: 'error', autoHideDuration: 2500 });
                failCb();
            })
    }

    const handleSubmitCandidateProfile = (value: CandidateProfileType, moveToNext: boolean, updated: boolean) => {
        const status = moveToNext ? 'EXPERIENCE_AND_EXPERTISE' : "PROFILE_DETAILS";
        handleProfileDataChange({
            ...candidateProfileData,
            ...value,
            profileUpdateState: status
        });
        const afterSaveCurrentStep = () => {
            if (moveToNext) {
                setCompletedStep((prev) => Math.max(currentStep, prev));
                setCurrentStep(2);
            }
        }

        if (updated) {
            setLoading(true);
            saveCurrentStepDetails({
                ...candidateProfileData,
                ...value,
                profileUpdateState: status
            }, () => {
                afterSaveCurrentStep();
                setLoading(false);
            }, () => {
                setLoading(false);
            })
        } else {
            afterSaveCurrentStep();
        }
    }

    const handleSubmitExp = (value: CandidateExperienceExpertiseType, moveToNext: boolean, updated: boolean) => {
        const status = moveToNext ? 'MAKE_A_PITCH' : "EXPERIENCE_AND_EXPERTISE";
        handleProfileDataChange({
            ...candidateProfileData,
            ...value,
            profileUpdateState: status
        })

        const afterSaveCurrentStep = () => {
            if (moveToNext) {
                setCompletedStep((prev) => Math.max(currentStep, prev));
                props.handleProfileCompleted();  // Profile information filled successfully
            }
        }

        if (updated) {
            setLoading(true);
            saveCurrentStepDetails({
                ...candidateProfileData,
                ...value,
                profileUpdateState: status
            }, () => {
                afterSaveCurrentStep();
            }, () => {
                setLoading(false);
            })
        } else {
            afterSaveCurrentStep();
        }
    }

    const handleStepChangeThroughHeader = (stepIdx: number) => {
        if (stepIdx > MAX_STEP) {
            props.handleProfileCompleted();
        } else {
            setCurrentStep(stepIdx);
        }
    }

    const getCurrentStepDetails = () => {
        if (currentStep === 0) {
            return <ResumeUpload
                handleSubmit={handleResumeSelect}
                loading={loading}
            />
        }
        if (currentStep === 1) {
            return <JobStepsContainer
                handleNextClick={handleNextClick}
                handlePrevClick={handlePrevClick}
                currentStep={currentStep}
                completedStep={completedStep}
                hideMakeAPitch={props.hideMakeAPitch}
                changeStep={handleStepChangeThroughHeader}
                loadingStep={autoSaveLoaderStep}
            >
                <CandidateProfile
                    handleSubmit={handleSubmitCandidateProfile}
                    candidateProfileSubmitBtnRef={candidateProfileSubmitBtnRef}
                    initialValue={candidateProfileData}
                    handleFormDataChange={(val) => handleProfileDataChange(val, true)}
                />
            </JobStepsContainer>
        }
        if (currentStep === 2) {
            return <JobStepsContainer
                handleNextClick={handleNextClick}
                handlePrevClick={handlePrevClick}
                currentStep={currentStep}
                completedStep={completedStep}
                hideMakeAPitch={props.hideMakeAPitch}
                submitBtnTitle={props.hideMakeAPitch ? "Save & Complete" : undefined}
                changeStep={handleStepChangeThroughHeader}
                loadingStep={autoSaveLoaderStep}
            >
                <CandidateExperienceExpertise
                    initialValue={{
                        experiences: candidateProfileData.experiences,
                        expertises: candidateProfileData.expertises,
                        certifications: candidateProfileData.certifications,
                        licenses: candidateProfileData.licenses,
                    }}
                    handleSubmit={handleSubmitExp}
                    candidateExpertiseSubmitBtnRef={candidateExpertiseSubmitBtnRef}
                    handleFormDataChange={(val) => handleProfileDataChange(val, true)}
                />
            </JobStepsContainer>
        }
    }

    useLayoutEffect(() => {
        if (candidateProfileData.profileUpdateState && !firstTimeStepAutoMoved.current) {
            let stepIndex = 0, allStepCompleted = false;
            if (candidateProfileData.profileUpdateState === 'RESUMEUPLOAD') {
                stepIndex = 0;
            } else if (candidateProfileData.profileUpdateState === 'PROFILE_DETAILS') {
                stepIndex = 0;
                pollResumeParsing();
            } else if (candidateProfileData.profileUpdateState === 'EXPERIENCE_AND_EXPERTISE') {
                stepIndex = 2;
            } else {
                stepIndex = 2;
                allStepCompleted = true;
            }
            if (isNumber(props.defaultProfileStep) && props.defaultProfileStep !== -1) {
                setCurrentStep(props.defaultProfileStep);
            } else {
                setCurrentStep(stepIndex);
            }
            setCompletedStep(prev => {
                if (allStepCompleted) {
                    return stepIndex;
                }
                return Math.max(prev, stepIndex - 1)
            });
            firstTimeStepAutoMoved.current = true;
        }
    }, [candidateProfileData])

    return (
        <>
            <OverlayLoader loading={loading && currentStep !== 0} disableOverlay={true} />
            {getCurrentStepDetails()}
        </>
    )
}

export default Profile;