import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { PlusLg } from '@styled-icons/bootstrap/PlusLg';
import { ExpandMore } from '@styled-icons/material-outlined/ExpandMore';
import { Form, Formik, useFormikContext } from "formik";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { getValueBrowserStorage } from "services/browserStorageService";
import styled from "styled-components";
import { CandidateExperienceExpertiseType } from "types/OnboardCandidate";
import { Track_Id } from "utilities/constants";
import * as Yup from 'yup';
import Experience from "./Experience";
import Expertise from "./Expertise";
import FilesUpload from "./FilesUpload";
import { getScrollValue } from "utilities/commonUtils";

const DefaultProjectsSchema = Yup.object().shape({
    // keyProject: Yup.string().required('Key project is required'),
    impact: Yup.string().required('Impact is required'),
});

const DefaultExperienceSchema = Yup.object().shape({
    companyName: Yup.string().required('Company name is required'),
    currentJob: Yup.boolean().nullable(),
    experienceSummary: Yup.string().nullable(),
    profile: Yup.string().required('Profile is required'),
    projects: Yup.array().nullable().of(DefaultProjectsSchema),

    startDate: Yup.date()
        .required('Start date is required')
        .typeError('Invalid date format'),

    endDate: Yup.date()
        .nullable()  // Allows endDate to be null if currentJob is true
        .when('currentJob', {
            is: false,
            then: Yup.date()
                .required('End date is required')
                .min(Yup.ref('startDate'), 'End date must be later than or equal to start date'),
            otherwise: Yup.date().nullable(),
        }),
});

const DefaultExpertiseSchema = Yup.object().shape({
    rating: Yup.number().required('Rating is required'),
    skill: Yup.string().required('Skill is required'),
});

const CandidateExperienceExpertiseSchema = Yup.object().shape({
    experiences: Yup.array().of(DefaultExperienceSchema),
    expertises: Yup.array().of(DefaultExpertiseSchema),
});

const StyledWrapper = styled.div`
    .MuiAccordionDetails-root {
        display: block;
        text-align: left;
    }

    hr {
        background: #bfd1ff;
        margin: 2.5rem 0;
    }

    .small-input-element {
        max-width: 120px;
    }

    .error-font {
        width: max-content;
    }
`

const CandidateExperienceExpertise = (props: {
    handleSubmit: (value: CandidateExperienceExpertiseType, moveToNext: boolean, updated: boolean) => void;
    candidateExpertiseSubmitBtnRef: React.MutableRefObject<HTMLInputElement | null>;
    initialValue: CandidateExperienceExpertiseType;
    handleFormDataChange: (value: CandidateExperienceExpertiseType) => void;
}) => {
    const candidateExperienceExpertiseForm = useRef<any>(null);

    const handleSubmit = (value: CandidateExperienceExpertiseType) => {
        const newValue = { ...value };
        props.handleSubmit(newValue, true, true);
    }

    const handleSave = (value: CandidateExperienceExpertiseType) => {
        props.handleSubmit({ ...value }, false, true);
    }

    const moveErrorFieldToView = () => {
        candidateExperienceExpertiseForm.current?.moveErrorFieldToView();
    }

    useEffect(() => {
        const scrollableDiv = document.getElementById('onboard-content-form-id');
        if (scrollableDiv && scrollableDiv instanceof HTMLElement) {
            scrollableDiv.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [])

    return (
        <StyledWrapper>
            <Formik
                initialValues={{ ...props.initialValue }}
                validationSchema={CandidateExperienceExpertiseSchema}
                onSubmit={handleSubmit}
            >
                <Form>
                    <CandidateExperienceExpertiseForm
                        handleFormDataChange={props.handleFormDataChange}
                        handleSave={handleSave}
                        ref={candidateExperienceExpertiseForm}
                    />
                    <input type="submit" hidden ref={props.candidateExpertiseSubmitBtnRef} onClick={moveErrorFieldToView} />
                </Form>
            </Formik>
        </StyledWrapper>
    );
}

const AccordionName = {     // keep key and value same
    experience: 'experience',
    expertise: 'expertise',
    licenses: 'licenses',
    certificates: 'certificates'
}

const CandidateExperienceExpertiseForm = forwardRef((props: {
    handleFormDataChange: (value: CandidateExperienceExpertiseType) => void;
    handleSave: (value: CandidateExperienceExpertiseType) => void;
}, ref) => {

    const { values, errors, submitCount, setFieldValue } = useFormikContext<CandidateExperienceExpertiseType>();
    const formError: any = errors;
    const addProjectBtnRef = useRef<HTMLInputElement | null>(null);
    const calledFirstTime = useRef<boolean>(true);

    const trackId = getValueBrowserStorage(Track_Id) ?? "";
    const directoryLicenses = `${(getValueBrowserStorage('candidateId') as string)}/Licenses/${trackId}`;
    const directoryCertificates = `${(getValueBrowserStorage('candidateId') as string)}/Certificates/${trackId}`;

    const [accordionOpen, setAccordionOpen] = useState<Set<String>>(new Set(Object.keys(AccordionName)));

    useEffect(() => {
        if (!calledFirstTime.current) {
            props.handleFormDataChange(values);
        } else {
            calledFirstTime.current = false;
        }
    }, [values])

    useImperativeHandle(ref, () => ({
        moveErrorFieldToView: () => {
            let accOped = false;
            if (errors.certifications && !accordionOpen.has(AccordionName.certificates)) { expandAccordion(AccordionName.certificates); accOped = true; }
            if (errors.experiences && !accordionOpen.has(AccordionName.experience)) { expandAccordion(AccordionName.experience); accOped = true; }
            if (errors.expertises && !accordionOpen.has(AccordionName.expertise)) { expandAccordion(AccordionName.expertise); accOped = true; }
            if (errors.licenses && !accordionOpen.has(AccordionName.licenses)) { expandAccordion(AccordionName.licenses); accOped = true; }

            setTimeout(() => {      // Calling this function once Dom has printed by error message
                moveErrorFieldToView();
            }, accOped ? 800 : 0)
        },
    }));

    const moveErrorFieldToView = () => {
        const elements = document.querySelectorAll('.text-danger.error-font');
        if (elements && elements.length > 0) {
            const targetElement = elements[0] as HTMLElement;
            const scrollableParent = document.getElementById('onboard-content-form-id');

            // Scroll the element into view
            if (targetElement && scrollableParent instanceof HTMLElement) {
                const value = getScrollValue(targetElement, scrollableParent);
                scrollableParent.scrollTo({
                    top: (value ?? 0) - 50,
                    behavior: 'smooth',
                });
            }

            // Add a temporary attention effect
            targetElement.style.transition = "background-color 0.5s ease";
            targetElement.style.backgroundColor = "yellow"; // Highlight color

            // Remove the highlight after a short delay
            setTimeout(() => {
                targetElement.style.backgroundColor = ""; // Reset to original color
            }, 2500);
        }
    };

    const expandAccordion = (key: string) => {
        setAccordionOpen((prev) => {
            const copySet = new Set(prev);
            copySet.add(key);
            return copySet;
        })
    }

    const toggleAccordion = (key: string) => {
        setAccordionOpen((prev) => {
            const copySet = new Set(prev);
            if (accordionOpen.has(key)) {
                copySet.delete(key);
            } else {
                copySet.add(key);
            }
            return copySet;
        })
    }

    return (
        <>
            <Accordion
                expanded={accordionOpen.has(AccordionName.experience)}
                className="mb-4"
            >
                <AccordionSummary
                    expandIcon={<ExpandMore width={'25px'} onClick={() => toggleAccordion(AccordionName.experience)} />}
                    aria-controls="candidate-experience"
                    id="candidate-experience-id"
                >
                    <div className="d-flex align-items-center justify-content-between w-100" onClick={() => toggleAccordion(AccordionName.experience)}>
                        <h5>
                            Add Your Experience (Optional)
                        </h5>
                        <div className="btn btn-link" onClick={(e) => {
                            e.stopPropagation();
                            addProjectBtnRef.current?.click()
                        }}>
                            <PlusLg width={'15px'} className="mr-2" style={{ marginTop: '-4px' }} />
                            <span>Add another Experience</span>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Experience
                        addProjectBtnRef={addProjectBtnRef}
                        formError={formError}
                        setFieldValue={setFieldValue}
                        submitCount={submitCount}
                        values={values}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={accordionOpen.has(AccordionName.expertise)}
                className="mb-4"
            >
                <AccordionSummary
                    expandIcon={<ExpandMore width={'25px'} onClick={() => toggleAccordion(AccordionName.experience)} />}
                    aria-controls="candidate-expertise"
                    id="candidate-expertise-id"
                >
                    <div className="d-flex align-items-center justify-content-between w-100" onClick={() => toggleAccordion(AccordionName.expertise)}>
                        <h5>
                            Add Your Skills/ Areas of Expertise (Optional)
                        </h5>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {values.expertises?.length > 0 && <div className="row mb-2">
                        <div className="col-9">
                            <span className="text-muted small">Your Skills</span>
                        </div>
                        <div className="col-2">
                            <span className="text-muted small">Level of Expertise</span>
                        </div>
                    </div>}
                    <Expertise
                        formError={formError}
                        setFieldValue={setFieldValue}
                        submitCount={submitCount}
                        values={values}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={accordionOpen.has(AccordionName.licenses)}
                className="mb-4"
            >
                <AccordionSummary
                    expandIcon={<ExpandMore width={'25px'} onClick={() => toggleAccordion(AccordionName.experience)} />}
                    aria-controls="candidate-licenses"
                    id="candidate-licenses-id"

                >
                    <div className="d-flex align-items-center justify-content-between w-100" onClick={() => toggleAccordion(AccordionName.licenses)}>
                        <h5>
                            Add Your Relevant Licenses (Optional)
                        </h5>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <FilesUpload
                        files={values.licenses}
                        maxFileSizeInBytes={5 * 1024 * 1024}
                        setFieldValue={setFieldValue}
                        fieldName="licenses"
                        directory={directoryLicenses}
                        saveFiles={(files) => {
                            props.handleSave({ ...values, "licenses": files })
                        }}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={accordionOpen.has(AccordionName.certificates)}
                className="mb-4"
            >
                <AccordionSummary
                    expandIcon={<ExpandMore width={'25px'} onClick={() => toggleAccordion(AccordionName.experience)} />}
                    aria-controls="candidate-certifications"
                    id="candidate-certifications-id"

                >
                    <div className="d-flex align-items-center justify-content-between w-100" onClick={() => toggleAccordion(AccordionName.certificates)}>
                        <h5>
                            Add Your Relevant Certificates (Optional)
                        </h5>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <FilesUpload
                        files={values.certifications}
                        maxFileSizeInBytes={5 * 1024 * 1024}
                        setFieldValue={setFieldValue}
                        fieldName="certifications"
                        directory={directoryCertificates}
                        saveFiles={(files) => {
                            props.handleSave({ ...values, "certifications": files })
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    )
})



export default CandidateExperienceExpertise;