import { ChatGptUserType } from 'containers/GPTChatBotContainer/useGPTChatBot';
import { IConversation } from 'containers/InterviewMeContainer';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { evaluationPlatformService } from 'services';
import { RootState, useAppDispatch } from 'store';
import { getAvailableSharedQuestion, getNotificationMsg, setAnswer, setCurrentAnsVersionId, setQuestionFeedbackViewed, setTriggerFeedback, updateQuestionStatusText } from 'store/evaluationPlatform';
import { IGetChatHistory, ISaveResponseForQuestionOfCandidateTracks } from 'types';
import { DEFAULT_TOKEN, MENUS, QUESTION_STATUS_TEXT, SAVING_TEXT } from 'utilities/constants';
import {
    notEmpty
} from 'utilities/helperFunctions';
import { getQuestionStatus } from 'utilities/landingPageUtil';
import { getInterviewMeChatHistory, setCurrentAnswerId, setSketchInProgress } from '../../../store/splitView';
import { extractNumericValue } from '../../../utilities/helperFunctions';
import InterviewMeContainer from '../../InterviewMeContainer';
import InterviewMe from '../../InterviewMeContainer/InterviewMe';
import AnswerTabContainer from '../Components/AnswerTabContainer';
import QuestionDescription from '../Question/QuestionDescription';
import SplitPaneWrapper from '../SplitPaneWrapper';
import useFeedback from '../hooks/useFeedback';
import { Answer, AnswerInner, Capability, IAnswerFeedback, ICandidateTrackData, IQuestion } from '../types';
import { answerType } from '../utils/utils';
import AnswerCTA, { ToogleTypes } from './AnswerCTA';
import { ISketchUpdate } from './SketchingPane';


export interface ITriggerFeedback {
    questionId: string;
    expertId: string;
    answerId: string;
}

interface IAnswerPane {
    isMeetingView?: boolean;
    questionPaneSize?: string;
    refreshStructuredFeedback?: () => void;
    onboardMode?: boolean;
    customActionBtn?: React.ReactNode[];
}

const AnswerPane = forwardRef((props: IAnswerPane, ref) => {
    const { isMeetingView, questionPaneSize, refreshStructuredFeedback } = props;
    const dispatch = useAppDispatch();
    const rteRef = useRef<ReactQuill | null>(null);
    const { currentAnsVersionId, currentQuestionId } = useSelector((state: any) => state.evaluationPlatform);
    const selectedCapabilityId = useSelector((state: RootState) => state.evaluationPlatform.selectedCapabilityId);
    const { showInterviewMeBot, showInterviewMeCodeBot } = useSelector((state: RootState) => state.splitView.bot);
    const questionStatusText = useSelector((state: RootState) => state.evaluationPlatform.questionStatusText);
    const { statusText, submitFeedbackToExpert } = useFeedback();
    const [tabValue, setTabValue] = useState(0);
    const [isNotInterviewed, setIsNotInterviewed] = useState<boolean>(false);
    const [tabvalueChanged, setTabValueChanged] = useState<boolean>(false);
    const candidateId = useSelector((state: RootState) => state.evaluationPlatform?.candidate?._id);
    const [answerVersion, setAnswerVersion] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState<IQuestion | undefined>(undefined);
    const [currentAnswer, setCurrentAnswer] = useState<Answer | undefined>(undefined);
    const [toggleType, setToggleType] = useState<ToogleTypes | undefined | false>();
    const [codingPortalReferenceId, setCodingPortalReferenceId] = useState<string>("");
    const queryParams = new URLSearchParams(window.location.search);
    const meetingId: string | undefined = queryParams.get("meetingId") ?? undefined;
    const mode = queryParams.get("mode") || "";

    const questionStatus = currentQuestion?.question && getQuestionStatus(currentQuestion?.question);
    const candidateTrack: ICandidateTrackData = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack[0])
    const triggerFeedback = useSelector((state: RootState) => state.evaluationPlatform.triggerFeedback);
    const key = selectedCapabilityId ? `capability_${selectedCapabilityId}_${currentQuestion?.question._id}` : '';

    const selectedCapability: Capability | undefined = candidateTrack?.capabilities?.find(
        capability => capability.capabilityId === selectedCapabilityId
    );


    const submitFeedbackHandler = async (expertId: string, hideNotification?: boolean) => {
        if (currentQuestion && currentAnswer) {
            const res = await submitFeedbackToExpert(expertId, currentQuestion, currentAnswer, hideNotification);
            return res;
        }
    }

    const handleInterviewMe = () => {
        if (currentQuestion?.question.answerType === answerType.CODE) {
            setTimeout(() => {
                setCodingPortalReferenceId("coding-pane-root");
            }, 0);
        }
    }

    const handleInterviewMeAnswerSave = (conversation: IConversation[]) => {
        const formattedHTML = conversation
            .filter(con => !con.isError && !!con.text)
            .map(({ type, text }, index) => {
                const formattedText = type === ChatGptUserType.BOT ? `<br><br><b>${text}</b><br>` : `<br><p>${text}</p>`;
                return formattedText;
            })
            .join('');

        if (rteRef && rteRef.current) {
            (rteRef.current as any).setEditorValue(formattedHTML);
        }
    };


    const handleFeedbackReceivedEvent = () => {
        if (
            questionStatus === QUESTION_STATUS_TEXT.FEEDBACK_RECEIVED ||
            questionStatus === QUESTION_STATUS_TEXT.LOOP_FEEDBACK_RECEIVED
        ) {
            const questionAnswerId = currentAnswer?.answer._id || "";
            const feedbackId = currentAnswer?.feedbacks[0]?._id || "";
            evaluationPlatformService
                .candidateViewedExpertFeedback({
                    token: DEFAULT_TOKEN,
                    candidateTrackId: candidateTrack.candidateTrackId,
                    questionId: currentQuestion?.question?._id || "",
                    candidateId,
                    questionAnswerId,
                    feedbackId,
                }).then((res: any) => {
                    if (res.output) {
                        dispatch(setQuestionFeedbackViewed({
                            capabilities: currentQuestion?.question.capabilities,
                            questionId: currentQuestion?.question._id,
                            questionAnswerId: questionAnswerId,
                        }));
                        const feedbacks: IAnswerFeedback[] = [{
                            ...res.output,
                            _id: res.output.feedbackId
                        }];
                        if (currentAnswer) {
                            const answer: Answer = { answer: currentAnswer.answer, feedbacks };
                            setCurrentAnswer(answer);
                            const selectedIndex = currentAnsVersionId && currentQuestion?.answers.findIndex((x: any) => x.answer._id === currentAnsVersionId);
                            if (selectedIndex > -1 && currentQuestion) {
                                currentQuestion.answers[selectedIndex] = answer;
                                setCurrentQuestion(currentQuestion)
                            }
                        }
                        refreshStructuredFeedback && refreshStructuredFeedback();
                        dispatch(getNotificationMsg({ trackId: candidateTrack.candidateTrackId, "menu": MENUS.TRACK }));
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    };

    const getChatHistory = async () => {
        if (candidateId && currentAnswer?.answer._id && !props.onboardMode) {
            const payload: IGetChatHistory = {
                token: DEFAULT_TOKEN,
                candidateId: candidateId,
                questionAnswerId: currentAnswer.answer._id
            }
            dispatch(getInterviewMeChatHistory(payload));
        }
    }

    useEffect(() => {
        if (props.onboardMode) {
            const newCurrentQuestion: IQuestion | undefined = selectedCapability?.questions?.find((question: IQuestion) => question.question._id === currentQuestionId) || selectedCapability?.questions[0];
            if (newCurrentQuestion?.question.answerType !== answerType.CODE) {
                setTabValue(2);
            }
        }
    }, [props.onboardMode, currentQuestionId])

    useEffect(() => {
        const newCurrentQuestion: IQuestion | undefined = selectedCapability?.questions?.find((question: IQuestion) => question.question._id === currentQuestionId) || selectedCapability?.questions[0];
        const selectedIndex = currentAnsVersionId && newCurrentQuestion?.answers.findIndex((x: any) => x.answer._id === currentAnsVersionId);
        if (selectedIndex !== -1) {
            const newCurrentAnswer = newCurrentQuestion?.answers[selectedIndex || 0];
            setAnswerVersion(selectedIndex || 0);
            setCurrentQuestion(newCurrentQuestion);
            setCurrentAnswer(newCurrentAnswer);
            if (newCurrentQuestion?.question.answerType === answerType.CODE && !tabvalueChanged) {
                setTabValue(1);
            }
        }
    }, [selectedCapability, currentQuestionId, currentAnsVersionId]);

    useEffect(() => {
        if (notEmpty(triggerFeedback)
            && triggerFeedback?.questionId === currentQuestion?.question._id
            && triggerFeedback?.answerId === currentAnswer?.answer._id && currentQuestion && currentAnswer) {
            submitFeedbackHandler(triggerFeedback?.expertId || "");
            dispatch(setTriggerFeedback(undefined));
        }
        if (currentAnswer) {
            handleFeedbackReceivedEvent()
        }
    }, [triggerFeedback, currentAnswer]);

    useEffect(() => {
        if (currentAnswer) {
            getChatHistory();
        }
    }, [currentAnswer]);

    useEffect(() => {
        selectedCapability?.questions && getSharedQuestions();
    }, [selectedCapability?.questions])

    const handleTabChange = (_: any, newValue: number) => {
        setTabValueChanged(true);
        setTabValue(newValue);
    };

    const setQuestionStatusText = (text: string) => {
        dispatch(updateQuestionStatusText(text));
    }

    const handleSaveQuestion = async (answer: string, type: string, codeType?: string, questionAnswerId?: string, sketchAvailable?: boolean) => {
        const payload: ISaveResponseForQuestionOfCandidateTracks = {
            ...(currentAnswer?.answer || {}),
            candidateTrackId: candidateTrack?.candidateTrackId,
            questionId: currentQuestion?.question._id || "",
            capabilityIds: (currentAnswer?.answer && currentAnswer?.answer?.capabilityIds) ? [...new Set(currentAnswer.answer.capabilityIds)] : [selectedCapabilityId],
            token: DEFAULT_TOKEN,
            answer: type === answerType.RICH_TEXT ? answer : (currentAnswer?.answer?.answer || ""),
            codeType: type === answerType.CODE && codeType ? codeType : currentAnswer?.answer?.codeType,
            codeAnswer: type === answerType.CODE ? answer : currentAnswer?.answer?.codeAnswer,
            questionAnswerId: questionAnswerId || currentAnswer?.answer?._id,
            sketchAvailable: currentAnswer?.answer.sketchAvailable || sketchAvailable,
            meetingId: meetingId,
            mode: mode
        };
        setQuestionStatusText(SAVING_TEXT);

        try {
            const res = await evaluationPlatformService.saveResponseForQuestionOfCandidateTrack(payload);
            setQuestionStatusText('Saved');
            const answer = res.output;
            const data = {
                ...answer,
                _id: answer.questionAnswerId,
                answer: answer.answer,
                updateAt: new Date(answer.updateAt).toUTCString(),
            };
            dispatch(setAnswer({
                capabilities: currentQuestion?.question.capabilities,
                questionId: currentQuestion?.question?._id || "",
                answer: data,
                isUpdate: !!currentAnswer,
            }))
            setCurrentAnswer({ answer: data, feedbacks: [] });
            dispatch(setCurrentAnswerId(data?._id));
            if (currentQuestion?.answers) {
                const questionClone = { ...currentQuestion };
                if (!questionClone.answers || !questionClone.answers.length) {
                    questionClone.answers = [{ answer: data, feedbacks: [] }];
                }
                setCurrentQuestion(questionClone);
            }
            return { ...data };
        } catch (error) {
            console.error('Error saving question:', error);
            setQuestionStatusText('An error occurred. Please try again.');
            return null;
        } finally {
            if (type === answerType.DRAWING) {
                dispatch(setSketchInProgress(false));
            }
        };
    };

    const RTOnChange = async (value: string, oldValue?: string, source?: string) => {
        return await handleSaveQuestion(value, answerType.RICH_TEXT)
    }

    const codeOnChange = (newCode: string, codeType: string) => {
        handleSaveQuestion(newCode, answerType.CODE, codeType);
    }

    const sketchOnChange = (data: ISketchUpdate) => {
        handleSaveQuestion(currentAnswer?.answer?.answer || "", answerType.DRAWING, undefined, data.questionAnswerId, true)
    }

    const handlePreviousVersion = () => {
        if (answerVersion > 0) {
            dispatch(setCurrentAnsVersionId({
                questionId: currentQuestion?.question._id,
                ansVersionId: currentQuestion?.answers[answerVersion - 1]?.answer._id
            }))
        }
    };

    const handleNextVersion = () => {
        if (answerVersion < (currentQuestion?.answers.length || 0) - 1) {
            dispatch(setCurrentAnsVersionId({
                questionId: currentQuestion?.question._id,
                ansVersionId: currentQuestion?.answers[answerVersion + 1]?.answer._id
            }))
        }
    };

    const onRecordSubmit = (cantainRecording?: boolean) => {
        const recordingText = "[This answer contains recordings]";
        let updatedAns = "" + (currentAnswer?.answer?.answer || "");
        if ((!currentAnswer || currentAnswer?.answer?.answer?.indexOf(recordingText) === -1) && cantainRecording) {
            updatedAns = "[This answer contains recordings]\n" + updatedAns;
        }
        else {
            updatedAns = updatedAns.replace("[This answer contains recordings]\n", "");
        }
        handleSaveQuestion(updatedAns, answerType.RICH_TEXT);
    }

    const getSharedQuestions = () => {
        const payload = {
            questionIds: selectedCapability?.questions?.map((q: any) => q.question._id),
            candidateTrackId: candidateTrack.candidateTrackId,
        };
        dispatch(getAvailableSharedQuestion(payload));
    }

    const isFullWidth = questionPaneSize && extractNumericValue(questionPaneSize) === 0;

    useImperativeHandle(ref, () => ({
        async handleSaveRt(value: string) {
            return await RTOnChange(value);
        },
        async submitFeedbackHandler(expertId: string, hideNotification: boolean) {
            return await submitFeedbackHandler(expertId, hideNotification);
        },
    }));

    return (
        <>
            <SplitPaneWrapper sashHeight='0px' direction="horizontal" key={key} draggable={false} initialPaneSize={`calc(100% - 50px`}>
                <SplitPaneWrapper direction="horizontal" initialPaneSize={isFullWidth ? "92%" : "100%"}>
                    <AnswerTabContainer
                        isMeetingView={isMeetingView}
                        tabValue={tabValue}
                        handleTabChange={handleTabChange}
                        currentQuestion={currentQuestion}
                        currentAnswer={currentAnswer}
                        sketchOnChange={sketchOnChange}
                        questionStatusText={questionStatusText}
                        onRecordSubmit={onRecordSubmit}
                        answerVersion={answerVersion}
                        toggleType={toggleType}
                        codeOnChange={codeOnChange}
                        RTOnChange={RTOnChange}
                        rteRef={rteRef}
                        handlePreviousVersion={handlePreviousVersion}
                        handleNextVersion={handleNextVersion}
                        onShare={getSharedQuestions}
                        portalReferenceId={codingPortalReferenceId}
                        onboardMode={props.onboardMode}
                    />
                    {isFullWidth && <QuestionDescription question={{
                        id: currentQuestion?.question._id!,
                        title: currentQuestion?.question.title || "",
                        description: currentQuestion?.question.description || "",
                    }} />}
                </SplitPaneWrapper>
                <div className='px-2 border-top' id="answer-cta">
                    <AnswerCTA
                        tabValue={tabValue}
                        isNotInterviewed={isNotInterviewed}
                        questionStatusText={questionStatusText}
                        submitFeedbackHandler={submitFeedbackHandler}
                        setActiveToggleType={setToggleType}
                        isMeetingView={isMeetingView}
                        updateAnswer={(data: AnswerInner) => setCurrentAnswer({ answer: data, feedbacks: [] })}
                        onInterviewMe={handleInterviewMe}
                        onboardMode={props.onboardMode}
                        customActionBtn={props.customActionBtn}
                    />
                </div>
            </SplitPaneWrapper>
            {showInterviewMeCodeBot && <InterviewMe type={answerType.CODE} onClose={() => setCodingPortalReferenceId("")} />}
            {showInterviewMeBot && <InterviewMeContainer onSave={handleInterviewMeAnswerSave} />}
        </>
    );
})

export default memo(AnswerPane);
