import { IFocussedModule } from "containers/TalkToExpert/meetingTypes";

export function extractParams(params: any) {
  if (params[0] === '?') params = params.slice(1)

  return params
    .split('&')
    .reduce((acc: any, curr: any) => {
      const param = curr.split('=');
      return { [param[0]]: param[1], ...acc }
    }, {})
}

export const getUUIDFromURL = (path: string) => {
  return path.substring(path.lastIndexOf('/') + 1);
}

export const getFileNameFromURL = (url: string) => {
  if (url) {
    const tempArr = url.split(".");
    let ext;
    if (tempArr[tempArr.length - 1].length) {
      ext = tempArr[tempArr.length - 1];
    }
    var m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return m[1] + "." + ext;
    }
  }
  return "";
}

export const removeUnwantedTextFromFileName = (url: string) => {
  let name = getFileNameFromURL(url);
  return remUnWanTxtHelper(name);
}

export const getFolderPathAfterDomainName = (url: string) => {
  if (!url) return url;
  if (!url.includes(".com/")) {
    return decodeURIComponent(url);
  }
  return remUnWanTxtHelper(url?.split(".com/")[1]);
}

export const convertHttpsToHttpFromUrl = (url: string) => {
  if (url == null) return url;
  return "http" + url.substring(url.indexOf(':'), url.length);
}

export const downloadFile = (folderPath: string) => {
  const link = document.createElement('a');
  link.href = folderPath;
  link.setAttribute('download', '');
  document.body.appendChild(link);
  link.click();
}

export const extractTextFromHTML = (htmlString: string) => {
  let plainText = htmlString.replace(/<[^>]+>/g, ' ');
  return plainText;
}

const remUnWanTxtHelper = (name: string) => {
  let index = name?.indexOf("?");
  return decodeURIComponent(name?.substring(0, index == -1 ? name.length : index));
}

export const getTreeCheckedKeys = (treeData: IFocussedModule[]) => {
  const keys: string[] = [];
  for (let i = 0; i < treeData.length; i++) {
    const cat = treeData[i];
    !cat.children?.length && keys.push(cat.entityId + "-" + cat.entity);
    for (let j = 0; j < cat.children?.length; j++) {
      const subcat = cat.children[j];
      !subcat.children?.length && keys.push(cat.entityId + "-" + cat.entity + "-" + subcat.entityId + "-" + subcat.entity);
      for (let k = 0; k < subcat.children?.length; k++) {
        const cap = subcat.children[k];
        !cap.children?.length && keys.push(cat.entityId + "-" + cat.entity + "-" + subcat.entityId + "-" + subcat.entity + "-" + cap.entityId + "-" + cap.entity);
        for (let l = 0; l < cap.children?.length; l++) {
          const ques = cap.children[l];
          keys.push(cat.entityId + "-" + cat.entity + "-" + subcat.entityId + "-" + subcat.entity + "-" + cap.entityId + "-" + cap.entity + "-" + ques.entityId + "-" + ques.entity)
        }
      }
    }
  }
  return keys;
}
