import { IconButton } from "@material-ui/core";
import { FileEarmarkText } from "@styled-icons/bootstrap/FileEarmarkText";
import { Upload } from "@styled-icons/boxicons-regular/Upload";
import axios from "axios";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { round } from "lodash";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { evaluationPlatformService } from "services";
import { getValueBrowserStorage } from "services/browserStorageService";
import styled from "styled-components";
import { FileInformation } from "types/OnboardCandidate";
import { convertHttpsToHttpFromUrl, getFolderPathAfterDomainName, triggerDownload } from "utilities/commonUtils";
import { Candidate_Track_Id, DefaultToastSettings } from "utilities/constants";
import { Delete as DeleteIcon } from '@styled-icons/fluentui-system-regular/Delete';
import { IconContainer } from 'components/Common/IconContainer';

const StyledContainer = styled.div`
    .upload-icon-container {
        display: flex;
        justify-content: start;
        gap: 1rem;
        align-items: center;
    }
    .help-icon {
        border-radius: 20%;
        border: 1px solid #325cd5;
    }
    .help-icon svg {
        color: #325cd5;
    }
    .no-pointer-event {
        pointer-events: none;
    }
`

const FilesUpload = (props: {
    files: FileInformation[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    saveFiles: (value: any) => void;
    maxFileSizeInBytes: number;
    fieldName: string,
    directory: string,
}) => {

    const { enqueueSnackbar } = useSnackbar();
    const inputEleRef = useRef<HTMLInputElement | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const candidateTrackId = getValueBrowserStorage(Candidate_Track_Id) ?? "";

    const handleUpload = () => {
        inputEleRef.current?.click();
    }

    const validateFileSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        event.target.value = '';
        if (file?.size && file.size > props.maxFileSizeInBytes) {
            enqueueSnackbar('File size should be less then 5MB', { variant: 'error', autoHideDuration: 2500 });
            return;
        }
        uploadFile(file);
    }

    const uploadFile = (file: File | undefined) => {
        if (!file) return
        setLoading(true);
        if (file) {
            const newFileName = file?.name?.trim();
            const reader = new FileReader();

            const handleActionAfterUpload = (err?: any) => {
                if (err) {
                    setLoading(false);
                    alert('Failed with error: ' + err);
                    enqueueSnackbar('Failed with error: ' + err, { variant: 'success', autoHideDuration: 2500 });
                    return;
                }
                const location = props.directory + '/' + newFileName;
                props.setFieldValue(props.fieldName, [...props.files, {
                    docName: newFileName,
                    fileSize: file?.size,
                    docUrl: location
                }]);
                props.saveFiles([...props.files, {
                    docName: newFileName,
                    fileSize: file?.size,
                    docUrl: location
                }])
                setLoading(false);
            }

            reader.onloadend = () => {
                const buffer = Buffer.from(reader.result as any);

                // Getting SignedInCredentials
                evaluationPlatformService.getS3SignedInCredentials({
                    path: `${props.directory}/${newFileName}`, candidateTrackId
                }).then((res) => {
                    let bucketUrl = res.output.url;
                    // Uploading to bucket
                    axios.put(bucketUrl, buffer)
                        .then((res) => {
                            handleActionAfterUpload();
                        }).catch(err => {
                            console.log(err);
                            axios.put(convertHttpsToHttpFromUrl(bucketUrl), buffer)
                                .then((res) => {
                                    handleActionAfterUpload();
                                }).catch(err => {
                                    handleActionAfterUpload(err);
                                })
                        })
                }).catch(err => {
                    handleActionAfterUpload(err);
                });
            };

            reader.readAsArrayBuffer(file);
        }
    };

    const downloadFile = (url: string) => {
        let folderPath = getFolderPathAfterDomainName(url);
        setLoading(true);
        evaluationPlatformService.getSignedURLToGETFile({ candidateTrackId: candidateTrackId ? candidateTrackId : '', path: folderPath })
            .then(res => {
                triggerDownload(res.output.url);
                setLoading(false);
                enqueueSnackbar('File downloaded!', { variant: 'success', autoHideDuration: 2500 });
            })
            .catch((e) => {
                enqueueSnackbar('Failed to download file!', { variant: 'error', autoHideDuration: 2500 });
                setLoading(false);
            })
    }

    const handleAttachmentRemove = (url: string) => {
        setLoading(true);
        evaluationPlatformService.deleteS3File({ candidateTrackId, path: getFolderPathAfterDomainName(url) })
            .then(res => {
                console.log("File removed")
            })
            .catch(err => toast.error('Unable to delete file. Please try again!', DefaultToastSettings))
            .finally(() => {
                const files = props.files ?? [];
                const filteredFiles = files.filter((file) => file.docUrl !== url);
                props.setFieldValue(props.fieldName, filteredFiles);
                props.saveFiles(filteredFiles);
                setLoading(false);
            })
    }

    return (
        <StyledContainer>
            <OverlayLoader loading={loading} disableOverlay={true} />
            {props.files?.map((file, idx) => {
                return (
                    <div className="upload-icon-container mb-2" key={idx + file.docName}>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id={'download-info-legend-id'}>{'Download file'}</Tooltip>}
                        >
                            <IconButton onClick={() => {
                                downloadFile(file.docUrl);
                            }} className="help-icon">
                                <FileEarmarkText width={'1rem'} />
                            </IconButton>
                        </OverlayTrigger>
                        <div className="text-left">
                            <span className="d-block small text-muted">{"License"}</span>
                            <span className="text-muted">{file.docName}</span><span className="text-muted">{` | File Size: ${round((file.fileSize ?? 0) / (1024 * 1024), 2)} MB`}</span>
                        </div>
                        <div>
                            <IconContainer height="25px" icon={DeleteIcon} color={'red'} onClick={() => handleAttachmentRemove(file.docUrl)} />
                        </div>
                    </div>
                )
            })}
            <div className="upload-icon-container mb-2">
                <IconButton onClick={handleUpload} className="help-icon">
                    <Upload width={'1rem'} />
                </IconButton>
                <div className="text-left">
                    <span className="d-block small text-muted">{"Upload License"}</span>
                    <span className="text-muted">Format: pdf | File Size: Up to {round(props.maxFileSizeInBytes / (1024 * 1024), 2)} MB</span>
                </div>
            </div>
            <input type="file" ref={inputEleRef} onChange={(e) => validateFileSize(e)} hidden accept=".pdf" />
        </StyledContainer>
    )
}

export default FilesUpload;