import AudioRecorder from 'components/AudioRecorder';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { Expert_Login, QUESTION_STATUS_TEXT } from 'utilities/constants';
import { Answer, IQuestion } from '../types';
import { isPlacementTrack } from 'utilities/helperFunctions';


interface IRecordPane {
    currentAnswer?: Answer;
    candidateId?: string;
    currentQuestion: IQuestion;
    answerVersion: number;
    isReadOnly?: boolean;
    capabilityId?: string;
    className?: string;
    setIsRecording?: (isRecording?: boolean) => void,
    onsubmit?: (isCancel?: boolean, containRecording?: boolean) => void;
}

const RecordPane = ({
    candidateId,
    currentAnswer,
    currentQuestion,
    answerVersion,
    isReadOnly,
    onsubmit,
    capabilityId,
    setIsRecording,
    className,
}: IRecordPane) => {
    const isExpertLogin = getValueBrowserStorage(Expert_Login) !== null;
    const trackId = getValueBrowserStorage('candidateTrackId');
    const selectedCapabilityId = useSelector(
        (state: any) => state.evaluationPlatform.selectedCapabilityId
    );
    const [directory, setDirectory] = useState<string>('');

    const isDisabled =
        isExpertLogin || (isPlacementTrack() && window.location.pathname !== "/onboardCandidate") || isReadOnly ||
        (currentAnswer &&
            ((currentQuestion?.question &&
                currentQuestion.question.status !== QUESTION_STATUS_TEXT.UNANSWERED ||
                answerVersion > 0) &&
                ((currentQuestion?.question &&
                    currentQuestion.question.status !== QUESTION_STATUS_TEXT.UNANSWERED &&
                    currentQuestion.question.status !== QUESTION_STATUS_TEXT.ANSWERED) ||
                    answerVersion > 0)));

    const handleSubmit = (containRecordings?: boolean) => {
        onsubmit && onsubmit(containRecordings);
    };

    useEffect(() => {
        const newDirectory =
            (candidateId || getValueBrowserStorage('candidateId') as string) +
            '/' +
            (capabilityId || selectedCapabilityId) +
            '/' +
            currentQuestion?.question?._id +
            '/recordings/v' +
            ((currentQuestion.answers?.length || 1) - (currentAnswer ? answerVersion : 0));

        setDirectory(newDirectory);
    }, [currentAnswer, answerVersion, candidateId, currentQuestion, selectedCapabilityId, capabilityId]);

    return (
        <div className={className}>
            {directory && (
                <AudioRecorder
                    isReadOnly={isDisabled}
                    handleSubmit={handleSubmit}
                    directory={directory}
                    candidateTrackId={trackId || ''}
                    allowVoiceRecorder={true}
                    showOldRecordings={true}
                    setIsRecording={setIsRecording}
                />
            )}
        </div>
    );
};

export default RecordPane;