import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import Profile from "containers/Profile";
import { useMessagePopup } from "context/messagePopup";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getValueBrowserStorage } from "services/browserStorageService";
import { RootState } from "store";
import { getCandidateProfile, setProfileUpdatedByUser } from "store/evaluationPlatform";
import styled from "styled-components";
import { Candidate_Id, Expert_Login } from "utilities/constants";

const StyledWrapper = styled.div`
    .content-container {
        overflow: auto;
        height: calc(100vh - 72px);
    }
`

const ProfileReview = () => {

    const location = useLocation<{ showProfileCompletionMessage?: boolean }>();
    const showProfileCompletionMessage = location?.state?.showProfileCompletionMessage;
    const message = useMessagePopup();
    const history = useHistory();

    const dispatch = useDispatch();
    const loadingCandidateActivity = useSelector((state: RootState) => state.evaluationPlatform.loading);
    const candidateProfileData = useSelector((state: RootState) => state.evaluationPlatform.candidateProfileOnboard);

    const expertLogin = (getValueBrowserStorage(Expert_Login) == null) ? false : true;

    const loadCandidateProfile = () => {
        const candidateId = getValueBrowserStorage(Candidate_Id);
        dispatch(getCandidateProfile(candidateId!));
    }

    const handleProfileCompleted = () => {
        message.success("You have successfully completed your profile.");
        dispatch(setProfileUpdatedByUser({ profileCompleted: true }));
        history.push('/question')
    }

    useEffect(() => {
        if (showProfileCompletionMessage && !expertLogin) {
            message.info("Kindly complete your profile to unlock all website features.");
        }
        loadCandidateProfile();
    }, [])

    return (
        <StyledWrapper>
            <OverlayLoader loading={loadingCandidateActivity} disableOverlay={true} />
            <div className="content-container">
                {candidateProfileData && <Profile
                    handleProfileCompleted={handleProfileCompleted}
                    hideMakeAPitch
                />}
            </div>
        </StyledWrapper>
    )
}

export default ProfileReview;