import { Avatar, Checkbox, InputBase, MenuItem, Radio, Select, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Webcam } from '@styled-icons/boxicons-regular/Webcam';
import axios from "axios";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { Country } from "country-state-city";
import { Form, Formik, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { evaluationPlatformService } from "services";
import { getValueBrowserStorage } from "services/browserStorageService";
import styled from "styled-components";
import { OptionsDataType } from "types";
import { CandidateProfileType, WorkAuthorizationType } from "types/OnboardCandidate";
import { convertHttpsToHttpFromUrl, getScrollValue } from "utilities/commonUtils";
import { Candidate_Track_Id, EquityOptions, Track_Id } from "utilities/constants";
import currencyList from "utilities/currency";
import timezoneList from "utilities/timezone";
import * as Yup from 'yup';
import LocationSelector from "./LocationSelector";
import { workingMode, workTypes } from "./OnboardCandidateContants";

const StyledWrapper = styled.div`
    .candidate-image-wrapper {
        position: relative;
        display: inline-block;
    }

    .candidate-image {
        border-radius: 50%;
        width: 5rem;
        height: 5rem;
        transition: 0.3s ease;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .candidate-image-wrapper:hover .overlay {
        opacity: 1;
    }

    .webcam-icon {
        color: white;
        width: 2rem;
        height: 2rem;
    }

    hr {
        background: #bfd1ff;
        margin: 2.5rem 0;
    }

    td {
        padding: 0.3rem;
    }

    .small-input-element {
        max-width: 120px;
    }

    .error-font {
        width: max-content;
    }
`;

const ValidationSchema = Yup.object({
    fullname: Yup.string()
        .required("This field is required"),
    email: Yup.string().email()
        .required("This field is required"),
    linkedinProfile: Yup.string().url()
        .required("This field is required"),
    workType: Yup.string()
        .required("This field is required"),
    workMode: Yup.array()
        .of(Yup.string())
        .min(1, "At least one working mode is required")
        .required("This field is required"),
    selectedLocations: Yup.array<OptionsDataType[]>()
        .required("This field is required"),
    preferredWorkTimezone: Yup.array()
        .of(Yup.string())
        .min(1, "This field is required")
        .required("This field is required"),
    equity: Yup.string()
        .required("This field is required"),
    currency: Yup.string()
        .required("This field is required"),
    expectedSalaryMin: Yup.number()
        .required("These fields are required")
        .min(0, "Min annual salary cannot be less then 0")
        .max(1e9, "Min annual salary cannot be more then 1 billion")
        .test('min-less-than-max', 'Min annual salary should be less than max annual salary', function (value) {
            const { expectedSalary } = this.parent;
            return value === undefined || value === null || expectedSalary === undefined || value < expectedSalary;
        }),

    expectedSalary: Yup.number()
        .min(0, "Max annual salary cannot be less then 0")
        .max(1e9, "Min annual salary cannot be more then 1 billion")
        .required("These fields are required"),

    expectedSalaryHourlyMin: Yup.number()
        .required("These fields are required")
        .min(0, "Min hourly salary cannot be less then 0")
        .max(1e9, "Min annual salary cannot be more then 1 billion")
        .test('min-less-than-max', 'Min hourly salary should be less than max hourly salary', function (value) {
            const { expectedSalaryHourly } = this.parent;
            return value === undefined || value === null || expectedSalaryHourly === undefined || value < expectedSalaryHourly;
        }),

    expectedSalaryHourly: Yup.number()
        .min(0, "Max hourly salary cannot be less then 0")
        .max(1e9, "Min annual salary cannot be more then 1 billion")
        .required("These fields are required"),
});

const CandidateProfile = (props: {
    handleSubmit: (value: CandidateProfileType & { selectedLocations: OptionsDataType[] }, moveToNext: boolean, updated: boolean) => void;
    candidateProfileSubmitBtnRef: React.MutableRefObject<HTMLInputElement | null>;
    initialValue: CandidateProfileType & { selectedLocations: OptionsDataType[] };
    handleFormDataChange: (value: CandidateProfileType) => void;
}) => {
    const [candidateAuthorizedToWork, setCandidateAuthorizedToWork] = useState<{ [country: string]: boolean }>({});
    const candidateProfileComponent = useRef<any>(null);

    const getWorkAuthorizationData = (candidateAuthorizedToWork: { [country: string]: boolean }) => {
        const workAuthorization: WorkAuthorizationType[] = [];
        for (let countryCode in candidateAuthorizedToWork) {
            workAuthorization.push({
                country: countryCode,
                authorizationStatus: candidateAuthorizedToWork[countryCode]
            })
        }
        return workAuthorization;
    }

    const handleSubmit = (value: CandidateProfileType & { selectedLocations: OptionsDataType[] }) => {
        const workAuthorization: WorkAuthorizationType[] = getWorkAuthorizationData(candidateAuthorizedToWork);
        const newValue = { ...value, workAuthorization };
        props.handleSubmit(newValue, true, true);
    }

    const handleSave = (value: CandidateProfileType & { selectedLocations: OptionsDataType[] }) => {
        const workAuthorization: WorkAuthorizationType[] = getWorkAuthorizationData(candidateAuthorizedToWork);
        props.handleSubmit({ ...value, workAuthorization }, false, true);
    }

    const authorizedCandidateToWork = (country: string,
        canWork: boolean,
        values: CandidateProfileType & { selectedLocations: OptionsDataType[] }) => {
        const workAuthorization = {
            ...candidateAuthorizedToWork,
            [country]: canWork
        };
        setCandidateAuthorizedToWork(workAuthorization)
        props.handleFormDataChange({ ...values, workAuthorization: getWorkAuthorizationData(workAuthorization) });
    }

    const moveErrorFieldToView = () => {
        candidateProfileComponent.current?.moveErrorFieldToView();
    }

    useEffect(() => {
        if (props.initialValue.workAuthorization) {
            const workAuth: { [country: string]: boolean } = {};
            for (const work of props.initialValue.workAuthorization) {
                workAuth[work.country] = work.authorizationStatus;
            }
            setCandidateAuthorizedToWork(workAuth);
        }
    }, [])

    useEffect(() => {
        const scrollableDiv = document.getElementById('onboard-content-form-id');
        if (scrollableDiv && scrollableDiv instanceof HTMLElement) {
            scrollableDiv.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [])

    return (
        <StyledWrapper>
            <Formik
                initialValues={{ ...props.initialValue }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                <Form>
                    <CandidateProfileForm
                        candidateAuthorizedToWork={candidateAuthorizedToWork}
                        authorizedCandidateToWork={authorizedCandidateToWork}
                        handleFormDataChange={props.handleFormDataChange}
                        handleSave={handleSave}
                        ref={candidateProfileComponent}
                    />
                    <input type="submit" hidden ref={props.candidateProfileSubmitBtnRef} onClick={moveErrorFieldToView} />
                </Form>
            </Formik>
        </StyledWrapper>
    );
};

const CandidateProfileForm = forwardRef((props: {
    handleSave: (value: CandidateProfileType & { selectedLocations: OptionsDataType[] }) => void;
    candidateAuthorizedToWork: { [country: string]: boolean };
    authorizedCandidateToWork: (country: string, canWork: boolean, value: CandidateProfileType & { selectedLocations: OptionsDataType[] }) => void;
    handleFormDataChange: (value: CandidateProfileType) => void
}, ref) => {

    const trackId = getValueBrowserStorage(Track_Id) ?? "";
    const candidateTrackId = getValueBrowserStorage(Candidate_Track_Id) ?? "";
    const directory = `${(getValueBrowserStorage('candidateId') as string)}/Profile/${trackId}`;

    const [loading, setLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const candidateProfileImg = useRef<HTMLInputElement | null>(null);
    const calledFirstTime = useRef<boolean>(true);
    const { values, errors, submitCount, setFieldValue } = useFormikContext<CandidateProfileType & { selectedLocations: OptionsDataType[] }>();

    useImperativeHandle(ref, () => ({
        moveErrorFieldToView: () => {
            setTimeout(() => {      // Calling this function once Dom has printed by error message
                moveErrorFieldToView();
            })
        },
    }));

    const moveErrorFieldToView = () => {
        const elements = document.querySelectorAll('.text-danger.error-font');
        if (elements && elements.length > 0) {
            const targetElement = elements[0] as HTMLElement;
            const scrollableParent = document.getElementById('onboard-content-form-id');

            // Scroll the element into view
            if (targetElement && scrollableParent instanceof HTMLElement) {
                const value = getScrollValue(targetElement, scrollableParent);
                scrollableParent.scrollTo({
                    top: (value ?? 0) - 50,
                    behavior: 'smooth',
                });
            }

            // Add a temporary attention effect
            targetElement.style.transition = "background-color 0.5s ease";
            targetElement.style.backgroundColor = "yellow"; // Highlight color

            // Remove the highlight after a short delay
            setTimeout(() => {
                targetElement.style.backgroundColor = ""; // Reset to original color
            }, 2500);
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const maxSize = 5 * 1024 * 1024;
        const file = event.target.files?.[0];
        event.target.value = '';
        if (file?.size && file.size > maxSize) {
            enqueueSnackbar('File size should be less then 5MB', { variant: 'error', autoHideDuration: 2500 });
            return;
        }
        handleUpload(file);
    }

    const handleUpload = (file: File | undefined) => {
        if (!file) return
        setLoading(true);
        if (file) {
            const newFileName = file?.name?.trim();
            const reader = new FileReader();

            const handleActionAfterUpload = (err?: any) => {
                if (err) {
                    setLoading(false);
                    alert('Failed with error: ' + err);
                    enqueueSnackbar('Failed with error: ' + err, { variant: 'success', autoHideDuration: 2500 });
                    return;
                }
                const location = directory + '/' + file.name;
                evaluationPlatformService.getSignedURLToGETFile({
                    candidateTrackId: candidateTrackId,
                    path: location
                })
                    .then(res => {
                        setFieldValue("profileImgUrl", res?.output?.url ?? "");
                        props.handleSave({
                            ...values,
                            profileImgUrl: res?.output?.url
                        });
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    })
            }

            reader.onloadend = () => {
                const buffer = Buffer.from(reader.result as any);

                // Getting SignedInCredentials
                evaluationPlatformService.getS3SignedInCredentials({
                    path: `${directory}/${newFileName}`, candidateTrackId
                }).then((res) => {
                    let bucketUrl = res.output.url;
                    // Uploading to bucket
                    axios.put(bucketUrl, buffer)
                        .then((res) => {
                            handleActionAfterUpload();
                        }).catch(err => {
                            console.log(err);
                            axios.put(convertHttpsToHttpFromUrl(bucketUrl), buffer)
                                .then((res) => {
                                    handleActionAfterUpload();
                                }).catch(err => {
                                    handleActionAfterUpload(err);
                                })
                        })
                }).catch(err => {
                    handleActionAfterUpload(err);
                });
            };

            reader.readAsArrayBuffer(file);
        }
    };

    const getCountryFromCode = (code: string) => {
        const [countryCode, stateCode] = code.toUpperCase().split("-");
        const country = Country.getCountryByCode(countryCode);
        return {
            countryCode,
            countryName: country?.name
        };
    }

    const getAllCountriesInLocation = (selectedLocations: OptionsDataType[]): { countryCode: string, countryName: string }[] => {
        if (!selectedLocations || selectedLocations.length === 0) {
            return [];
        }

        const seenCountryCodes = new Set();
        const result = selectedLocations
            .map((location) => {
                const country = getCountryFromCode(location.parent ?? location.elementValue);
                return {
                    countryName: country.countryName!,
                    countryCode: country.countryCode!
                };
            })
            .filter((country) => {
                if (seenCountryCodes.has(country.countryCode)) {
                    return false;
                } else {
                    seenCountryCodes.add(country.countryCode);
                    return true;
                }
            });

        return result;
    };

    const handleLocationChange = (value: OptionsDataType[]) => {
        const locations = getAllCountriesInLocation(value);
        for (let location of locations) {
            if (!props.candidateAuthorizedToWork[location.countryCode]) {
                props.authorizedCandidateToWork(location.countryCode, false, values);
            }
        }
    }

    useEffect(() => {
        if (!calledFirstTime.current) {
            const valuesCopy = { ...values };
            if(valuesCopy.expectedSalaryHourlyMin && +valuesCopy.expectedSalaryHourlyMin > 1e9) delete valuesCopy.expectedSalaryHourlyMin;
            if(valuesCopy.expectedSalaryHourly && +valuesCopy.expectedSalaryHourly > 1e9) delete valuesCopy.expectedSalaryHourly;
            if(valuesCopy.expectedSalaryMin && +valuesCopy.expectedSalaryMin > 1e9) delete valuesCopy.expectedSalaryMin;
            if(valuesCopy.expectedSalary && +valuesCopy.expectedSalary > 1e9) delete valuesCopy.expectedSalary;

            props.handleFormDataChange(valuesCopy);
        } else {
            calledFirstTime.current = false;
        }
    }, [values])

    return (
        <>
            <OverlayLoader loading={loading} disableOverlay={true} />
            <div className="d-flex align-items-center mb-4">
                <div className="candidate-image-wrapper mr-2">
                    {!values.profileImgUrl && <Avatar src="" className="candidate-image" />}
                    {values.profileImgUrl && <img src={values.profileImgUrl} className="candidate-image" alt="Expert" />}
                    <div className="overlay" onClick={() => candidateProfileImg.current?.click()} >
                        <Webcam className="webcam-icon" />
                    </div>
                </div>
                <div className="text-left ml-3">
                    <span className="d-block mb-2">Add Your Profile Picture to</span>
                    <span>Make a Strong First Impression</span>
                </div>
                <input
                    type="file"
                    hidden
                    name="profilePhoto"
                    ref={candidateProfileImg}
                    onChange={(e) => handleFileUpload(e)}
                    accept="image/*"
                />
            </div>
            <div className="mb-4 text-left">
                <h5 className="mb-2">Contact Details</h5>
                <div className="row mb-4">
                    <div className="col">
                        <span className="text-muted">Name:</span>
                        <InputBase
                            placeholder="Enter your name"
                            name='fullname'
                            className="input-element mt-2"
                            value={values.fullname}
                            onChange={(e) => setFieldValue('fullname', e.target.value)}
                        />
                        {errors.fullname && submitCount > 0 && <span className="text-danger error-font">{errors.fullname}</span>}
                    </div>
                    <div className="col">
                        <span className="text-muted">Email:</span>
                        <InputBase
                            placeholder="Enter your email"
                            name='email'
                            className="input-element mt-2"
                            value={values.email}
                            onChange={(e) => setFieldValue('email', e.target.value)}
                            disabled
                        />
                        {errors.email && submitCount > 0 && <span className="text-danger error-font">{errors.email}</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <span className="text-muted">LinkedIn Profile Link:</span>
                        <InputBase
                            placeholder="Enter your linkedin profile link"
                            name='linkedinProfile'
                            className="input-element mt-2"
                            value={values.linkedinProfile}
                            onChange={(e) => setFieldValue('linkedinProfile', e.target.value)}
                        />
                        {errors.linkedinProfile && submitCount > 0 && <span className="text-danger error-font">{errors.linkedinProfile}</span>}
                    </div>
                </div>
            </div>
            <hr />
            <div className="mb-4 text-left">
                <h5 className="mb-2">Select Preferences</h5>
                <div>
                    <span className="mb-2 d-block">Which of these are your Preferred Work Arrangement/s?</span>
                    <div className="d-flex">
                        {
                            workTypes.map((type, idx) => {
                                return (
                                    <div className="d-flex align-items-center mr-3" key={type.key + idx}>
                                        <Radio
                                            className="p-0 mr-2"
                                            onChange={() => setFieldValue("workType", type.key)}
                                            checked={type.key === values.workType}
                                        />
                                        <span>{type.value}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {errors.workType && submitCount > 0 && <span className="text-danger error-font">{errors.workType}</span>}
            </div>
            <div className="mb-4 text-left">
                <span className="mb-2 d-block">What is your Preferred Work Mode?</span>
                <div className="d-flex">
                    {
                        workingMode.map((type, idx) => {
                            return (
                                <div className="d-flex align-items-center mr-3" key={type.key + idx}>
                                    <Checkbox className="p-0 mr-2" onChange={(e) => {
                                        if (e.target.checked) {
                                            setFieldValue("workMode", [...values.workMode, type.key]);
                                        } else {
                                            setFieldValue("workMode", values.workMode.filter(mode => mode !== type.key));
                                        }
                                    }}
                                        checked={(values.workMode ?? []).includes(type.key)}
                                    />
                                    <span>{type.value}</span>
                                </div>
                            )
                        })
                    }
                </div>
                {errors.workMode && submitCount > 0 && <span className="text-danger error-font">{errors.workMode}</span>}
            </div>
            <div className="mb-4 text-left">
                <span className="mb-2 d-block">Which Physical locations would you prefer working from?</span>
                <span className="mb-1 d-block text-muted">Location</span>
                <LocationSelector
                    disabled={false}
                    handleChangeLocation={(field, val) => {
                        setFieldValue(field, val);
                        handleLocationChange(val);
                    }}
                    selectedLocations={values.selectedLocations}
                    keyName={'selectedLocations'}
                />
                {errors.selectedLocations && submitCount > 0 && <span className="text-danger error-font">{errors.selectedLocations}</span>}
            </div>
            {values.selectedLocations?.length > 0 && <div className="mb-4 text-left">
                <span className="mb-2 d-block">Do you have work authorization for the below Location/s?</span>
                <table>
                    <tbody>
                        {getAllCountriesInLocation(values.selectedLocations)?.map((value, idx) => {
                            return (
                                <tr className="mb-2" key={value.countryCode + idx}>
                                    <td style={{ minWidth: '7rem' }}>{value.countryName}</td>
                                    <td style={{ minWidth: '4rem' }}>
                                        <div className="d-flex align-items-center mr-3">
                                            <Radio
                                                className="p-0 mr-1"
                                                onClick={(e) => props.authorizedCandidateToWork(value.countryCode, true, values)}
                                                checked={(props.candidateAuthorizedToWork[value.countryCode] + "") === "true"}
                                            />
                                            <span>{'Yes'}</span>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '4rem' }}>
                                        <div className="d-flex align-items-center mr-3">
                                            <Radio
                                                className="p-0 mr-1"
                                                onClick={(e) => props.authorizedCandidateToWork(value.countryCode, false, values)}
                                                checked={(props.candidateAuthorizedToWork[value.countryCode] + "") !== "true"}
                                            />
                                            <span>{'No'}</span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>}
            <div className="mb-4 text-left">
                <span className="mb-2 d-block">Which Time Zones would you prefer working in?</span>
                <Autocomplete
                    disableCloseOnSelect
                    value={values.preferredWorkTimezone}
                    options={timezoneList}
                    onChange={(e, value) => setFieldValue('preferredWorkTimezone', value)}
                    multiple
                    noOptionsText={'Please search timezone'}
                    renderOption={(option, { selected }) => {
                        return (
                            <>
                                <Checkbox
                                    style={{ marginRight: 8, color: '#315CD5' }}
                                    checked={selected}
                                />
                                {option}
                            </>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={'Please search timezone'}
                            variant="outlined"
                            size='small'
                        />
                    )}
                />
                {errors.preferredWorkTimezone && submitCount > 0 && <span className="text-danger error-font">{errors.preferredWorkTimezone}</span>}
            </div>
            <div className="mb-4 text-left">
                <span className="mb-2 d-block">What is your Preferred Mode of Compensation?</span>
                <div className="d-flex align-items-center mb-2">
                    <Radio className="p-0 mr-2" onChange={(e) => setFieldValue("equity", 'CASH_ANDOR_EQUITY')} checked={values.equity === 'CASH_ANDOR_EQUITY'} />
                    <span>{EquityOptions.CASH_ANDOR_EQUITY}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <Radio className="p-0 mr-2" onChange={(e) => setFieldValue("equity", 'ONLY_EQUITY')} checked={values.equity === 'ONLY_EQUITY'} />
                    <span>{EquityOptions.ONLY_EQUITY}</span>
                </div>
                {errors.equity && submitCount > 0 && <span className="text-danger error-font">{errors.equity}</span>}
            </div>
            <hr />
            <div className="mb-4 text-left">
                <h5 className="mb-2">Share your Salary Expectations</h5>
                <div className="d-flex align-items-center">
                    <span className="mr-3 d-block">Currency: </span>
                    <Select
                        variant="outlined"
                        placeholder="Select Currency"
                        value={values.currency}
                        onChange={(e, val) => setFieldValue('currency', e.target.value)}
                        className="input-element-select small-input-element"
                    >
                        {currencyList.map((cur, idx) => {
                            return (
                                <MenuItem
                                    value={cur.code}
                                    key={cur.code + idx}
                                >
                                    {cur.code}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    {errors.currency && submitCount > 0 && <span className="text-danger error-font">{errors.currency}</span>}
                </div>
            </div>
            <div className="mb-4 text-left d-flex align-items-center">
                <span className="mr-3">Annual Salary:</span>
                <div className="d-flex align-items-center mr-3">
                    <span className="d-block mr-2">Min</span>
                    <InputBase
                        placeholder="0000"
                        name='annualSalaryMin'
                        className="input-element mt-2 small-input-element"
                        value={values.expectedSalaryMin}
                        onChange={(e) => setFieldValue('expectedSalaryMin', e.target.value)}
                        type="number"
                    />
                </div>
                <div className="d-flex align-items-center mr-3">
                    <span className="d-block mr-2">Max</span>
                    <InputBase
                        placeholder="0000"
                        name='annualSalaryMax'
                        className="input-element mt-2 small-input-element"
                        value={values.expectedSalary}
                        onChange={(e) => setFieldValue('expectedSalary', e.target.value)}
                        type="number"
                    />
                </div>
                <span className="d-block mr-3">per year</span>
                {(errors.expectedSalaryMin || errors.expectedSalary) && submitCount > 0 && <span className="text-danger error-font">{errors.expectedSalaryMin ?? errors.expectedSalary}</span>}
            </div>
            <div className="mb-4 text-left d-flex align-items-center">
                <span className="mr-3">Hourly Salary:</span>
                <div className="d-flex align-items-center mr-3">
                    <span className="d-block mr-2">Min</span>
                    <InputBase
                        placeholder="0000"
                        name='expectedSalaryHourlyMin'
                        className="input-element mt-2 small-input-element"
                        value={values.expectedSalaryHourlyMin}
                        onChange={(e) => setFieldValue('expectedSalaryHourlyMin', e.target.value)}
                        type="number"
                    />
                </div>
                <div className="d-flex align-items-center mr-3">
                    <span className="d-block mr-2">Max</span>
                    <InputBase
                        placeholder="0000"
                        name='expectedSalaryHourly'
                        className="input-element mt-2 small-input-element"
                        value={values.expectedSalaryHourly}
                        onChange={(e) => setFieldValue('expectedSalaryHourly', e.target.value)}
                        type="number"
                    />
                </div>
                <span className="d-block mr-3">per hour</span>
                {(errors.expectedSalaryHourlyMin || errors.expectedSalaryHourly) && submitCount > 0 && <span className="text-danger error-font">{errors.expectedSalaryHourlyMin ?? errors.expectedSalaryHourly}</span>}
            </div>
        </>
    )
})

export default CandidateProfile;